@charset "UTF-8";
.sprite-basic {
  display: block;
  overflow: hidden;
  text-indent: -9999px; }

.sprite-basic-a, .header nav li span, .nav-side-left ul li span {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-indent: -9999px; }

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, menu, nav, section, menu, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background-color: transparent; }

body {
  line-height: 1; }

article, aside, dialog, figure, footer, header, hgroup, nav, section {
  display: block; }

ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

img {
  vertical-align: bottom; }

html {
  font-size: 62.5%;
  /*10px*/
  height: 100%; }

body {
  font-family: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  color: #83a2c5;
  background: #eeeeee;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  text-decoration: none;
  color: #83a2c5; }

a:visited {
  color: #83a2c5; }

a:hover {
  color: #83a2c5;
  text-decoration: underline; }

::selection {
  background: #e9e9e9; }

::-moz-selection {
  background: #e9e9e9; }

.u-tar {
  text-align: right; }

.u-tac {
  text-align: center; }

.u-fll {
  float: left; }

.u-flr {
  float: right; }

.clearfix:after, .wrap-product dd ul.pic-list:after {
  height: 0;
  visibility: hidden;
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  line-height: 0;
  overflow: hidden; }

.clearfix, .wrap-product dd ul.pic-list {
  _height: 1px;
  min-height: 1px;
  /*・･*/
  /*/
  height: auto;
  overflow: hidden;
  /**/ }

.u-show_pc {
  display: block; }
  @media only screen and (max-width: 979px) {
    .u-show_pc {
      display: none; } }
  @media only screen and (max-width: 680px) {
    .u-show_pc {
      display: none; } }

.u-show_pctb {
  display: block; }
  @media only screen and (max-width: 979px) {
    .u-show_pctb {
      display: block; } }
  @media only screen and (max-width: 680px) {
    .u-show_pctb {
      display: none; } }

.u-show_tbsp {
  display: none; }
  @media only screen and (max-width: 979px) {
    .u-show_tbsp {
      display: block; } }
  @media only screen and (max-width: 680px) {
    .u-show_tbsp {
      display: block; } }

.u-show_tb {
  display: none; }
  @media only screen and (max-width: 979px) {
    .u-show_tb {
      display: block; } }
  @media only screen and (max-width: 680px) {
    .u-show_tb {
      display: none; } }

.u-show_sp {
  display: none; }
  @media only screen and (max-width: 979px) {
    .u-show_sp {
      display: none; } }
  @media only screen and (max-width: 680px) {
    .u-show_sp {
      display: block; } }

@media only screen and (min-width: 980px) {
  .u-op2 {
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .u-op2:hover {
    opacity: 0.5; }
  .u-op a {
    display: block;
    background-color: #fff; }
    .u-op a img {
      transition-property: opacity;
      transition-duration: 0.5s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .u-op a:hover img {
      opacity: 0.6; } }

.u-spacer-bottom-5 {
  margin-bottom: 5px; }

.u-spacer-top-5 {
  margin-top: 5px; }

.u-spacer-bottom-10 {
  margin-bottom: 10px; }

.u-spacer-top-10 {
  margin-top: 10px; }

.u-spacer-bottom-15 {
  margin-bottom: 15px; }

.u-spacer-top-15 {
  margin-top: 15px; }

.u-spacer-bottom-20 {
  margin-bottom: 20px; }

.u-spacer-top-20 {
  margin-top: 20px; }

.u-spacer-bottom-30 {
  margin-bottom: 30px; }

.u-spacer-top-30 {
  margin-top: 30px; }

.u-spacer-bottom-35 {
  margin-bottom: 35px; }

.u-spacer-top-35 {
  margin-top: 35px; }

.u-spacer-bottom-40 {
  margin-bottom: 40px; }

.u-spacer-top-40 {
  margin-top: 40px; }

.u-spacer-bottom-50 {
  margin-bottom: 50px; }

.u-spacer-top-50 {
  margin-top: 50px; }

.u-spacer-bottom-60 {
  margin-bottom: 60px; }

.u-spacer-top-60 {
  margin-top: 60px; }

.u-spacer-bottom-70 {
  margin-bottom: 70px; }

.u-spacer-top-70 {
  margin-top: 70px; }

.u-spacer-bottom-80 {
  margin-bottom: 80px; }

.u-spacer-top-80 {
  margin-top: 80px; }

.u-spacer-bottom-90 {
  margin-bottom: 90px; }

.u-spacer-top-90 {
  margin-top: 90px; }

.u-spacer-bottom-100 {
  margin-bottom: 100px; }

.u-spacer-top-100 {
  margin-top: 100px; }

.u-spacer-bottom-145 {
  margin-bottom: 145px; }

.u-spacer-top-145 {
  margin-top: 145px; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }

@keyframes flash {
  0% {
    opacity: 0; }

  10% {
    opacity: 0; }

  25% {
    opacity: 0.5; }

  100% {
    opacity: 0; } }

@keyframes flash2 {
  0% {
    opacity: 0; }

  50% {
    opacity: 0.4; }

  100% {
    opacity: 0; } }

.fadeInOut-type1 {
  opacity: 0;
  transform: translate3d(0, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 1s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .fadeInOut-type1.show {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

.fadeInOut-type2 {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  transition-property: transform, opacity;
  transition-duration: 1s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .fadeInOut-type2.show {
    opacity: 1;
    margin-top: 1;
    transform: translate3d(0, 0, 0); }

.fadeoutNext {
  transform: translate3d(0, 100px, 0) !important;
  opacity: 0 !important; }

.flash {
  -o-animation: flash .2s linear 2;
  -moz-animation: flash .2s linear 2;
  -webkit-animation: flash .2s linear 2;
  animation: flash .2s linear 2; }

#wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  opacity: 0;
  transform: translate3d(0, -20px, 0); }
  #wrapper.nav-open {
    overflow-x: hidden; }
  #wrapper.show {
    transition-property: transform opacity;
    transition-duration: 1s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transform: translate3d(0, 0, 0);
    opacity: 1; }

.page-home #wrapper {
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.main {
  min-width: 1200px;
  padding-top: 1px;
  padding-bottom: 1px;
  position: relative;
  transition-property: transform;
  transition-duration: 0.75s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translate3d(0, 0, 0); }
  .main.nav-open {
    transform: translate3d(140px, 0, 0); }
  .main.news-main {
    background-color: #fff;
    background-image: url(../images/pc/bg-ground.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    min-height: 900px; }
  .main.music-main {
    background-color: #fff;
    background-image: url(../images/pc/bg-sky.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed; }
  .main.staff-main, .main.books-main {
    background-color: #fff;
    background-image: url(../images/pc/bg-sky2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-attachment: fixed; }
  .main.onair-main {
    background-color: #fff;
    background-image: url(../images/pc/bg-residential.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-attachment: fixed; }
  .main.product-main {
    background-color: #e3f6fb;
    background-image: url(../images/pc/product/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed; }
  .main.cast-main {
    background-color: #fff;
    background-image: url(../images/pc/cast/bg.jpg);
    background-repeat: no-repeat;
    background-position: center top; }
  .main.cast2-main {
    background-color: #fff;
    background-image: url(../images/pc/bg-ground.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed; }
  .main.introduction-main {
    background-color: #fff;
    background-image: url(../images/pc/bg-dragonfly.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed; }
  .main.story-main {
    background-color: #e6f3f7;
    background-image: url(../images/pc/bg-ball.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-attachment: fixed; }
  .main .box-ttl {
    position: absolute;
    text-align: right;
    right: 0;
    top: 0; }
    .main .box-ttl.type2 {
      top: 101px; }

.box-content {
  margin-top: 163px;
  max-width: 1300px;
  min-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .box-content.type2 {
    margin-top: 320px; }

.box-content-inner {
  width: 650px;
  margin-left: auto;
  margin-right: auto; }

.box-content-inner2 {
  width: 620px;
  margin-left: auto;
  margin-right: auto; }

@media only screen and (min-width: 1800px) {
  .staff-main {
    background-size: 100%; }
  .introduction-main {
    background-size: 100%; } }

.common-catch {
  position: absolute;
  left: -60px;
  top: 25px; }

.comment {
  display: inline-block;
  margin-left: 10px;
  background-image: url(/assets/images/sprite-common-pc.png);
  width: 16px;
  height: 14px;
  background-position: -360px -134px;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .comment:hover {
    transform: translate3d(0, 4px, 0); }
  .comment.y {
    background-position: -376px -82px; }
  .comment.pos2 {
    right: 0px; }
  .comment.big {
    background-image: url(/assets/images/sprite-cast-pc.png);
    width: 31px;
    height: 30px;
    background-position: -2464px -2163px;
    position: static;
    display: inline-block;
    margin-left: 10px; }
    .comment.big:hover {
      transform: translate3d(0, -4px, 0); }
  .comment.big2 {
    background-image: url(/assets/images/sprite-cast-pc.png);
    width: 31px;
    height: 30px;
    background-position: -2464px -2163px;
    position: absolute;
    left: 50px;
    bottom: 14px; }
    .comment.big2:hover {
      transform: translate3d(0, -4px, 0); }
  .comment.shine {
    background-image: url(/assets/images/sprite-common-pc.png);
    width: 57px;
    height: 56px;
    background-position: -334px 0px; }
    .comment.shine:hover {
      transform: translate3d(0, -4px, 0); }

.wrap-news {
  padding-bottom: 1px;
  border-radius: 2px; }
  .wrap-news .entry {
    background-color: rgba(253, 253, 249, 0.8);
    border: 1px solid #d6ddd0;
    padding: 40px;
    margin-bottom: 30px; }
    .wrap-news .entry.entry-special {
      border: 6px solid #6b8795; }
    .wrap-news .entry .date {
      font-size: 14px;
      font-size: 1.4rem;
      color: #4c5362;
      margin-bottom: 15px; }
      .wrap-news .entry .date.date-special .cat {
        margin-left: 0; }
      .wrap-news .entry .date .cat {
        display: inline-block;
        margin-left: 25px;
        font-size: 11px;
        font-size: 1.1rem;
        line-height: 1.27273;
        border: 1px solid #88b7c9;
        font-weight: bold;
        color: #88b7c9;
        padding: 2px 3px; }
        .wrap-news .entry .date .cat.date-special {
          margin-left: 0; }
    .wrap-news .entry .title {
      font-size: 18px;
      font-size: 1.8rem;
      line-height: 1.33333;
      color: #104f67;
      padding-bottom: 20px;
      margin-bottom: 30px;
      border-bottom: 1px solid #104f67; }
      .wrap-news .entry .title a {
        color: #104f67; }
      .wrap-news .entry .title.title-special {
        text-align: center;
        font-size: 24px;
        font-size: 2.4rem;
        line-height: 1.25; }
    .wrap-news .entry .entry-share {
      text-align: right;
      font-size: 0;
      margin-bottom: 38px; }
      .wrap-news .entry .entry-share li {
        margin-left: 30px;
        display: inline-block; }
      .wrap-news .entry .entry-share li.ico-twitter-s {
        position: relative; }
        .wrap-news .entry .entry-share li.ico-twitter-s:before {
          content: '';
          position: absolute;
          right: -18px;
          top: 0;
          background-image: url(/assets/images/sprite-common-pc.png);
          background-position: -380px -114px;
          width: 11px;
          height: 15px; }
        .wrap-news .entry .entry-share li.ico-twitter-s a {
          display: block;
          background-image: url(/assets/images/sprite-common-pc.png);
          background-position: -334px -114px;
          width: 21px;
          height: 18px; }
      .wrap-news .entry .entry-share li.ico-facebook-s a {
        display: block;
        background-image: url(/assets/images/sprite-common-pc.png);
        background-position: -334px -134px;
        width: 11px;
        height: 21px; }
    .wrap-news .entry .box-entry p {
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.42857;
      color: #4c5362;
      margin-bottom: 30px; }
      .wrap-news .entry .box-entry p:last-child {
        margin-bottom: 0; }
      .wrap-news .entry .box-entry p a {
        padding: 2px;
        line-height: 16px;
        display: inline-block;
        background-color: #fff799; }
    .wrap-news .entry .box-entry img {
      max-width: 100%;
      height: auto;
      display: block;
      margin-bottom: 30px; }
  .wrap-news .entry-list {
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    .wrap-news .entry-list:hover {
      background-color: rgba(255, 247, 153, 0.6); }
      .wrap-news .entry-list:hover a {
        text-decoration: none; }
  .wrap-news .box-page-numbers {
    text-align: center; }
  .wrap-news ul.page-numbers {
    display: inline-block;
    background-color: #fff799;
    margin-bottom: 30px; }
    .wrap-news ul.page-numbers li {
      display: inline-block;
      font-size: 14px;
      font-size: 1.4rem; }
      .wrap-news ul.page-numbers li a {
        padding: 4px 3px;
        display: block; }
      .wrap-news ul.page-numbers li span.current {
        padding: 4px 3px;
        color: #cccccc; }
  .wrap-news .box-cat {
    border: 1px solid #6a8795;
    margin-bottom: 60px; }
    .wrap-news .box-cat ul {
      text-align: center;
      padding-top: 15px;
      padding-bottom: 10px; }
      .wrap-news .box-cat ul li {
        display: inline-block;
        margin: 0 13px;
        font-weight: bold;
        font-size: 14px;
        font-size: 1.4rem; }
        .wrap-news .box-cat ul li a {
          color: #6a8795; }
        .wrap-news .box-cat ul li.current-cat a {
          color: #cccccc; }
  .wrap-news .back-index {
    text-align: center;
    margin-bottom: 60px; }
    .wrap-news .back-index a {
      display: block;
      background-color: #fff799;
      font-size: 14px;
      font-size: 1.4rem;
      padding: 10px; }

.wrap-books {
  width: 760px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px; }
  .wrap-books .box-books-title {
    margin-bottom: 58px; }
  .wrap-books .pic-books-title {
    float: left;
    margin-right: 24px; }
  .wrap-books .txt-books-title {
    float: left; }
    .wrap-books .txt-books-title h2 {
      padding-top: 10px;
      font-size: 30px;
      font-size: 3rem;
      line-height: 1.36667;
      color: #fff;
      font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      text-align: center;
      margin-bottom: 22px; }
  .wrap-books .btn-books-title {
    text-align: center; }
    .wrap-books .btn-books-title a {
      display: inline-block;
      color: #7fc6ee;
      background-color: #fff100;
      font-size: 18px;
      font-size: 1.8rem;
      padding: 10px 26px; }
  .wrap-books section.list-books h2 {
    margin-bottom: 38px;
    font-size: 25px;
    font-size: 2.5rem;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    color: #6a8795;
    display: inline-block;
    border: 1px solid #6a8795;
    padding: 16px 18px; }
  .wrap-books section.list-books h3 {
    margin-bottom: 20px;
    font-size: 20px;
    font-size: 2rem;
    color: #fff;
    text-align: center; }
    .wrap-books section.list-books h3 span {
      display: inline-block;
      padding-bottom: 15px;
      border-bottom: 1px solid #fff; }
  .wrap-books section.list-books p {
    color: #fff;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px; }
  .wrap-books section.list-books .list-books1_2 {
    margin-bottom: 35px;
    text-align: center; }
    .wrap-books section.list-books .list-books1_2 span {
      display: inline-block; }
  .wrap-books section.list-books ul {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap; }
    .wrap-books section.list-books ul li {
      width: 114px;
      margin-right: 15px;
      margin-bottom: 20px; }
      .wrap-books section.list-books ul li img {
        width: 100%;
        height: auto; }
      .wrap-books section.list-books ul li:nth-child(6n) {
        margin-right: 0; }

.wrap-cast section {
  min-width: 550px;
  height: 453px;
  text-align: center;
  direction: rtl;
  font-size: 0; }
  .wrap-cast section dl {
    display: inline-block;
    vertical-align: top;
    margin: 0 -2.5px;
    font-size: 0;
    line-height: 0; }
    .wrap-cast section dl dt {
      height: 115px; }
    .wrap-cast section dl dd {
      position: relative; }

.wrap-cast2 {
  padding-top: 110px;
  position: relative; }
  .wrap-cast2 section {
    width: 880px;
    height: 850px;
    margin-left: auto;
    margin-right: auto;
    position: relative; }
    .wrap-cast2 section .charactor-thumbs {
      position: absolute;
      left: 50%;
      top: -114px;
      width: 770px;
      height: 54px;
      margin-left: -394px; }
    .wrap-cast2 section div[class*="thumbs-"] {
      background-image: url(/assets/images/sprite-cast-pc.png);
      width: 54px;
      height: 54px;
      float: right;
      margin-left: 16px;
      cursor: pointer;
      z-index: 2; }
      .wrap-cast2 section div[class*="thumbs-"]:hover {
        opacity: 0.7; }
      .wrap-cast2 section div[class*="thumbs-"].current {
        opacity: 1;
        cursor: default; }
    .wrap-cast2 section .thumbs-harada {
      background-position: -2406px -2410px; }
      .wrap-cast2 section .thumbs-harada.current {
        background-position: -2350px -2410px; }
    .wrap-cast2 section .thumbs-nagakura {
      background-position: -2742px -2410px; }
      .wrap-cast2 section .thumbs-nagakura.current {
        background-position: -2686px -2410px; }
    .wrap-cast2 section .thumbs-seiha {
      background-position: -2854px -2410px; }
      .wrap-cast2 section .thumbs-seiha.current {
        background-position: -2798px -2410px; }
    .wrap-cast2 section .thumbs-kadowaki {
      background-position: -2966px -2410px; }
      .wrap-cast2 section .thumbs-kadowaki.current {
        background-position: -2910px -2410px; }
    .wrap-cast2 section .thumbs-mizugaki {
      background-position: -3078px -2410px; }
      .wrap-cast2 section .thumbs-mizugaki.current {
        background-position: -3022px -2410px; }
    .wrap-cast2 section .thumbs-kaionji {
      background-position: -2406px -2466px; }
      .wrap-cast2 section .thumbs-kaionji.current {
        background-position: -2350px -2466px; }
    .wrap-cast2 section .thumbs-sawaguchi {
      background-position: -2518px -2466px; }
      .wrap-cast2 section .thumbs-sawaguchi.current {
        background-position: -2462px -2466px; }
    .wrap-cast2 section .thumbs-higashidani {
      background-position: -3059px -2315px; }
      .wrap-cast2 section .thumbs-higashidani.current {
        background-position: -2574px -2466px; }
    .wrap-cast2 section .thumbs-nobunishi {
      background-position: -3059px -2259px; }
      .wrap-cast2 section .thumbs-nobunishi.current {
        background-position: -3059px -2203px; }
    .wrap-cast2 section .thumbs-tomura {
      background-position: -2518px -2410px; }
      .wrap-cast2 section .thumbs-tomura.current {
        background-position: -2462px -2410px; }
    .wrap-cast2 section .thumbs-yoshisada {
      background-position: -2630px -2410px; }
      .wrap-cast2 section .thumbs-yoshisada.current {
        background-position: -2574px -2410px; }
    .wrap-cast2 section div[class*="btn-arrow-"] {
      background-image: url(/assets/images/sprite-cast-pc.png);
      height: 25px;
      position: absolute;
      top: 9px;
      cursor: pointer;
      z-index: 3; }
      .wrap-cast2 section div[class*="btn-arrow-"]:hover {
        opacity: 0.7; }
    .wrap-cast2 section .btn-arrow-left {
      width: 15px;
      background-position: -2514px -2163px;
      left: 421px; }
    .wrap-cast2 section .btn-arrow-right {
      width: 15px;
      background-position: -2497px -2163px;
      left: 587px; }
    .wrap-cast2 section div[class*="box-"] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transform: translate3d(0, -10px, 0);
      z-index: 0; }
      .wrap-cast2 section div[class*="box-"].show {
        transition-property: transform opacity;
        transition-duration: 1s;
        transition-delay: 0s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        transform: translate3d(0, 0, 0);
        opacity: 1;
        z-index: 1; }
      .wrap-cast2 section div[class*="box-"] h2 {
        position: absolute;
        right: 0;
        top: 110px; }
        .wrap-cast2 section div[class*="box-"] h2.type2 {
          top: 30px; }
      .wrap-cast2 section div[class*="box-"] .face {
        position: absolute;
        right: 192px;
        top: 282px;
        background-image: url(/assets/images/sprite-cast-pc.png); }
      .wrap-cast2 section div[class*="box-"] dl {
        position: absolute;
        left: 0;
        top: 112px;
        width: 325px; }
        .wrap-cast2 section div[class*="box-"] dl dt {
          margin-bottom: 20px; }
          .wrap-cast2 section div[class*="box-"] dl dt ul {
            height: 185px;
            background-color: #fff799;
            position: relative;
            box-sizing: border-box; }
          .wrap-cast2 section div[class*="box-"] dl dt li {
            position: absolute;
            left: 2px;
            top: 3px;
            opacity: 0;
            transition-property: transform opacity;
            transition-duration: 1.2s;
            transition-delay: 0s;
            transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
            .wrap-cast2 section div[class*="box-"] dl dt li.show {
              opacity: 1; }
        .wrap-cast2 section div[class*="box-"] dl dd {
          color: #666666;
          font-size: 16px;
          font-size: 1.6rem;
          line-height: 1.625; }
      .wrap-cast2 section div[class*="box-"] ul.charactor-list {
        position: absolute;
        left: 333px;
        top: 34px; }
        .wrap-cast2 section div[class*="box-"] ul.charactor-list.long {
          top: 10px; }
          .wrap-cast2 section div[class*="box-"] ul.charactor-list.long li {
            height: 739px; }
        .wrap-cast2 section div[class*="box-"] ul.charactor-list li {
          position: absolute;
          top: 0;
          left: 0;
          background-repeat: no-repeat;
          background-image: url(/assets/images/sprite-cast-pc.png);
          width: 350px;
          height: 719px;
          opacity: 0;
          transition-property: transform opacity;
          transition-duration: 0.8s;
          transition-delay: 0s;
          transition-timing-function: ease; }
          .wrap-cast2 section div[class*="box-"] ul.charactor-list li.show {
            opacity: 1; }
    .wrap-cast2 section .box-harada .face {
      width: 120px;
      height: 184px;
      background-position: -2583px -2203px; }
    .wrap-cast2 section .box-harada li:nth-child(1) {
      background-position: -1760px -2203px; }
    .wrap-cast2 section .box-harada li:nth-child(2) {
      background-position: -1408px -741px; }
    .wrap-cast2 section .box-harada li:nth-child(3) {
      background-position: -1760px 0px; }
    .wrap-cast2 section .box-nagakura .face {
      width: 115px;
      height: 165px;
      background-position: -2112px -2410px; }
    .wrap-cast2 section .box-nagakura li:nth-child(1) {
      background-position: -1760px -721px; }
    .wrap-cast2 section .box-nagakura li:nth-child(2) {
      background-position: -2112px 0px; }
    .wrap-cast2 section .box-nagakura li:nth-child(3) {
      background-position: -2112px -721px; }
    .wrap-cast2 section .box-seiha .face {
      width: 118px;
      height: 170px;
      background-position: -2939px -2203px; }
    .wrap-cast2 section .box-seiha li:nth-child(1) {
      background-position: 0px -1482px; }
    .wrap-cast2 section .box-seiha li:nth-child(2) {
      background-position: -352px -1482px; }
    .wrap-cast2 section .box-seiha li:nth-child(3) {
      background-position: -704px -1482px; }
    .wrap-cast2 section .box-kadowaki .face {
      width: 107px;
      height: 173px;
      background-position: -2112px -2577px; }
    .wrap-cast2 section .box-kadowaki li:nth-child(1) {
      background-position: -1056px -1482px; }
    .wrap-cast2 section .box-kadowaki li:nth-child(2) {
      background-position: -1408px -1482px; }
    .wrap-cast2 section .box-kadowaki li:nth-child(3) {
      background-position: -1760px -1482px; }
    .wrap-cast2 section .box-mizugaki .face {
      width: 113px;
      height: 205px;
      background-position: -2112px -2203px; }
    .wrap-cast2 section .box-mizugaki li:nth-child(1) {
      background-position: -2112px -1482px; }
    .wrap-cast2 section .box-mizugaki li:nth-child(2) {
      background-position: -2464px 0px; }
    .wrap-cast2 section .box-mizugaki li:nth-child(3) {
      background-position: -2464px -721px; }
    .wrap-cast2 section .box-kaionji .face {
      width: 118px;
      height: 188px;
      background-position: -2463px -2203px; }
    .wrap-cast2 section .box-kaionji li:nth-child(1) {
      background-position: -2464px -1442px; }
    .wrap-cast2 section .box-kaionji li:nth-child(2) {
      background-position: -2816px 0px; }
    .wrap-cast2 section .box-kaionji li:nth-child(3) {
      background-position: -2816px -721px; }
    .wrap-cast2 section .box-sawaguchi .face {
      width: 119px;
      height: 149px;
      background-position: -2229px -2410px; }
    .wrap-cast2 section .box-sawaguchi li:nth-child(1) {
      background-position: -2816px -1442px; }
    .wrap-cast2 section .box-sawaguchi li:nth-child(2) {
      background-position: 0px -2203px; }
    .wrap-cast2 section .box-sawaguchi li:nth-child(3) {
      background-position: -352px -2203px; }
    .wrap-cast2 section .box-higashidani .face {
      width: 116px;
      height: 196px;
      background-position: -2227px -2203px; }
    .wrap-cast2 section .box-higashidani li:nth-child(1) {
      background-position: -704px -2203px; }
    .wrap-cast2 section .box-higashidani li:nth-child(2) {
      background-position: -1056px -2203px; }
    .wrap-cast2 section .box-higashidani li:nth-child(3) {
      background-position: -1408px -2203px; }
    .wrap-cast2 section .box-nobunishi .face {
      width: 115px;
      height: 182px;
      background-position: -2705px -2203px; }
    .wrap-cast2 section .box-nobunishi li:nth-child(1) {
      background-position: -1056px -741px; }
    .wrap-cast2 section .box-nobunishi li:nth-child(2) {
      background-position: -704px -741px; }
    .wrap-cast2 section .box-nobunishi li:nth-child(3) {
      background-position: 0px 0px; }
    .wrap-cast2 section .box-tomura .face {
      width: 116px;
      height: 192px;
      background-position: -2345px -2203px; }
    .wrap-cast2 section .box-tomura li {
      height: 739px; }
    .wrap-cast2 section .box-tomura li:nth-child(1) {
      background-position: -352px 0px; }
    .wrap-cast2 section .box-tomura li:nth-child(2) {
      background-position: -704px 0px; }
    .wrap-cast2 section .box-tomura li:nth-child(3) {
      background-position: -1056px 0px; }
    .wrap-cast2 section .box-yoshisada .face {
      width: 115px;
      height: 181px;
      background-position: -2822px -2203px; }
    .wrap-cast2 section .box-yoshisada li:nth-child(1) {
      background-position: -1408px 0px; }
    .wrap-cast2 section .box-yoshisada li:nth-child(2) {
      background-position: 0px -741px; }
    .wrap-cast2 section .box-yoshisada li:nth-child(3) {
      background-position: -352px -741px; }
    .wrap-cast2 section .bg-chara-shadow {
      position: absolute;
      left: 96px;
      top: 733px;
      width: 266px;
      height: 17px;
      background-position: -2816px -2163px;
      background-image: url(/assets/images/sprite-cast-pc.png); }

.wrap-staff dl {
  margin-bottom: 20px; }
  .wrap-staff dl dt {
    font-size: 33px;
    font-size: 3.3rem;
    letter-spacing: 0.2em;
    color: #fff799;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: bold;
    margin-bottom: 15px; }
  .wrap-staff dl dd {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 2.42857;
    letter-spacing: 0.2em;
    color: #104f67;
    font-weight: bold;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    position: relative; }
  .wrap-staff dl.cast-list {
    margin-bottom: 60px; }
    .wrap-staff dl.cast-list dd {
      width: 250px;
      float: left; }

.wrap-introduction {
  margin-bottom: 100px;
  text-align: center; }
  .wrap-introduction h2 {
    padding-bottom: 35px;
    border-bottom: 1px solid #104f67;
    margin-bottom: 33px; }
    .wrap-introduction h2.h-story {
      color: #4c5261;
      font-size: 34px;
      font-size: 3.4rem;
      line-height: 1.11765;
      font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      text-align: left;
      padding-bottom: 15px;
      margin-bottom: 20px;
      font-weight: normal; }
    .wrap-introduction h2.h-story2 {
      color: #4c5261;
      font-size: 24px;
      font-size: 2.4rem;
      font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      text-align: left;
      margin-top: 30px;
      padding-bottom: 15px;
      margin-bottom: 20px;
      font-weight: normal; }
  .wrap-introduction .txt-story-staff {
    color: #4c5261;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.42857;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    margin-bottom: 20px; }
  .wrap-introduction .box-story-nav {
    border: 1px solid #104f67;
    padding: 15px 0;
    margin-bottom: 70px; }
    .wrap-introduction .box-story-nav div {
      float: left;
      margin-left: 97px;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.78571; }
    .wrap-introduction .box-story-nav ul {
      float: right;
      margin-right: 115px;
      width: 280px; }
      .wrap-introduction .box-story-nav ul li {
        float: left;
        margin-left: 14px;
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 1.78571; }
    .wrap-introduction .box-story-nav a {
      color: #6a8795; }
    .wrap-introduction .box-story-nav .current a {
      color: #ccc; }
  .wrap-introduction .pic-story-main {
    background-size: 100%;
    background-repeat: none;
    margin-bottom: 20px;
    max-height: 360px;
    overflow: hidden; }
    .wrap-introduction .pic-story-main img {
      width: 100%;
      height: auto;
      transition-property: opacity;
      transition-duration: 0.5s;
      transition-delay: 0s;
      transition-timing-function: ease;
      opacity: 1; }
    .wrap-introduction .pic-story-main.hide img {
      opacity: 0; }
  .wrap-introduction .pic-story-list {
    display: flex;
    margin-bottom: 20px; }
    .wrap-introduction .pic-story-list li {
      background-color: #000;
      cursor: pointer;
      position: relative; }
      .wrap-introduction .pic-story-list li:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #000;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.2s;
        transition-delay: 0s;
        transition-timing-function: ease; }
      .wrap-introduction .pic-story-list li img {
        width: 100%;
        height: auto; }
      .wrap-introduction .pic-story-list li:hover:before, .wrap-introduction .pic-story-list li.current:before {
        opacity: 0.2; }
  .wrap-introduction .box-entry-story {
    text-align: left;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.42857;
    color: #6a8795; }

.box-comment {
  display: none;
  z-index: 1000;
  background-color: #c8cacf;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden; }
  .box-comment .box-comment-inner {
    background-color: #f2edea;
    padding: 70px;
    padding-bottom: 70px; }
    .box-comment .box-comment-inner .box-comment-inner2 {
      width: 720px;
      position: relative;
      margin-left: auto;
      margin-right: auto; }
    .box-comment .box-comment-inner h2 {
      position: absolute;
      right: 0;
      top: 0; }
    .box-comment .box-comment-inner dl {
      position: absolute;
      left: 0;
      bottom: 0; }
      .box-comment .box-comment-inner dl dt {
        margin-bottom: 20px; }
  .box-comment .comment-close {
    cursor: pointer;
    height: 1000px;
    width: 100%;
    background-color: #c8cacf; }
    .box-comment .comment-close span {
      position: relative;
      top: 30px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      background-image: url(/assets/images/sprite-common-pc.png);
      width: 72px;
      height: 57px;
      background-position: 0px -167px; }
  .box-comment.hatanaka .box-comment-inner2 {
    height: 500px; }
  .box-comment.uchiyama .box-comment-inner2 {
    height: 500px; }
  .box-comment.fujimaki .box-comment-inner2 {
    height: 550px; }
    .box-comment.fujimaki .box-comment-inner2 dl {
      bottom: 115px !important; }
  .box-comment.ono .box-comment-inner2 {
    height: 550px; }
    .box-comment.ono .box-comment-inner2 dl {
      bottom: 115px !important; }
  .box-comment.kimura .box-comment-inner2 {
    height: 550px; }
    .box-comment.kimura .box-comment-inner2 dl {
      bottom: 115px !important; }
  .box-comment.umehara .box-comment-inner2 {
    height: 550px; }
    .box-comment.umehara .box-comment-inner2 dl {
      bottom: 115px !important; }
  .box-comment.murase .box-comment-inner2 {
    height: 550px; }
    .box-comment.murase .box-comment-inner2 dl {
      bottom: 115px !important; }
  .box-comment.ishikawa .box-comment-inner2 {
    height: 550px; }
    .box-comment.ishikawa .box-comment-inner2 dl {
      bottom: 115px !important; }
  .box-comment.morishima .box-comment-inner2 {
    height: 550px; }
    .box-comment.morishima .box-comment-inner2 dl {
      bottom: 115px !important; }
  .box-comment.goda .box-comment-inner2 {
    height: 550px; }
    .box-comment.goda .box-comment-inner2 dl {
      bottom: 115px !important; }
  .box-comment.saito .box-comment-inner2 {
    height: 550px; }
    .box-comment.saito .box-comment-inner2 dl {
      bottom: 115px !important; }
  .box-comment.asano .box-comment-inner2 {
    height: 550px; }
  .box-comment.mochizuki .box-comment-inner2 {
    height: 550px; }
    .box-comment.mochizuki .box-comment-inner2 dl {
      bottom: 110px !important; }
  .box-comment.shimura .box-comment-inner2 {
    height: 550px; }
  .box-comment.kusama .box-comment-inner2 {
    width: 980px;
    height: 550px; }
  .box-comment.senju .box-comment-inner2 {
    height: 516px; }
    .box-comment.senju .box-comment-inner2 dl {
      bottom: 110px !important; }
  .box-comment .bg-ball {
    position: absolute;
    left: -91px;
    bottom: 0;
    width: 118px;
    height: 86px; }
    .box-comment .bg-ball .shadow {
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: url(/assets/images/sprite-common-pc.png);
      width: 81px;
      height: 10px;
      background-position: -217px -85px; }
    .box-comment .bg-ball .ball {
      position: absolute;
      right: 0;
      top: 0;
      background-image: url(/assets/images/sprite-common-pc.png);
      width: 87px;
      height: 83px;
      background-position: 0px 0px; }

.wrap-movie {
  width: 540px;
  margin-left: auto;
  margin-right: auto; }
  .wrap-movie ul {
    padding-bottom: 30px; }
    .wrap-movie ul li {
      margin-bottom: 60px; }
      .wrap-movie ul li div {
        margin-bottom: 15px; }
      .wrap-movie ul li p {
        text-align: center;
        font-size: 20px;
        font-size: 2rem;
        font-weight: bold; }

.wrap-onair h2 {
  text-align: center;
  padding-top: 112px;
  border-bottom: 1px solid #000;
  padding-bottom: 15px;
  margin-bottom: 30px; }
.wrap-onair p.info {
  margin-bottom: 70px;
  text-align: center; }
.wrap-onair p.note {
  font-size: 12px;
  font-size: 1.2rem;
  margin-bottom: 60px;
  text-align: left;
  color: #000; }
.wrap-onair .table-onair {
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  text-align: left; }
  .wrap-onair .table-onair dt {
    width: 40%;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.8125;
    margin-bottom: 12px;
    font-weight: bold;
    color: #000; }
  .wrap-onair .table-onair dd {
    width: 60%;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.8125;
    margin-bottom: 12px;
    color: #000; }
    .wrap-onair .table-onair dd span {
      display: block;
      font-size: 13px;
      font-size: 1.3rem;
      line-height: 2.23077; }

.wrap-music {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px; }
  .wrap-music .box-music {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px; }
  .wrap-music .pic {
    margin-right: 25px; }
  .wrap-music h2 {
    margin-bottom: 25px; }
  .wrap-music h3 {
    border-bottom: 1px solid #83a2c5;
    margin-bottom: 20px;
    padding-bottom: 17px; }
  .wrap-music p {
    margin-bottom: 30px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.42857;
    color: #666666; }
    .wrap-music p span {
      display: inline-block;
      transform: scaleX(0.93);
      width: 108%;
      transform-origin: left top; }
    .wrap-music p a {
      padding: 1px;
      background-color: #fff799; }

.wrap-product {
  width: 798px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 94px;
  margin-bottom: 60px; }
  .wrap-product h2 {
    margin-bottom: 45px; }
  .wrap-product p {
    margin-bottom: 40px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 2;
    color: #000000; }
    .wrap-product p a {
      padding: 3px;
      background-color: #fff799; }
    .wrap-product p .note {
      font-size: 12px;
      font-size: 1.2rem;
      line-height: 2.66667; }
  .wrap-product nav {
    margin-bottom: 60px; }
  .wrap-product dt {
    color: #e3f6fb;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 2;
    background-color: #000;
    display: inline-block;
    padding: 0 10px;
    margin-bottom: 10px; }
  .wrap-product dd {
    margin-bottom: 15px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.42857;
    color: #000000; }
    .wrap-product dd ul {
      margin-bottom: 15px; }
      .wrap-product dd ul li {
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 1.42857;
        color: #000000; }
    .wrap-product dd ul.pic-list li {
      margin-right: 20px;
      margin-bottom: 20px;
      max-width: 300px;
      float: left; }
    .wrap-product dd .text-product-common {
      font-size: 20px;
      font-size: 2rem;
      margin-bottom: 15px; }
      .wrap-product dd .text-product-common strong {
        font-weight: normal; }
    .wrap-product dd .pic-product-common {
      margin-bottom: 15px; }
  .wrap-product .list-note li {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.66667;
    color: #000000; }
  .wrap-product .list-product {
    display: flex;
    margin-bottom: 20px; }
    .wrap-product .list-product li {
      box-sizing: border-box;
      width: 40px;
      cursor: pointer;
      margin-right: 20px;
      color: #83a2c5;
      text-align: center; }
      .wrap-product .list-product li a {
        display: block;
        border: 3px solid #fff799;
        font-size: 14px;
        font-size: 1.4rem;
        padding: 9px 0; }
      .wrap-product .list-product li.long {
        width: 162px;
        margin-right: 17px; }
        .wrap-product .list-product li.long a {
          padding: 9px 0px; }
      .wrap-product .list-product li.current a, .wrap-product .list-product li:hover a {
        text-decoration: none;
        background-color: #fff799; }
  .wrap-product .bnr-product {
    display: flex; }
    .wrap-product .bnr-product li {
      background-color: #fffde4;
      width: 340px;
      margin-right: 20px; }
      .wrap-product .bnr-product li a {
        background-color: transparent;
        display: block;
        padding: 10px 0 10px 20px; }
  .wrap-product #box-products {
    position: relative; }
  .wrap-product .box-product-inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px; }
  .wrap-product .box-product-right {
    margin-left: 20px; }
  .wrap-product .bnr-buy a {
    display: block;
    width: 200px;
    height: 42px;
    background-color: #121212;
    color: #faf4aa;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 3;
    text-align: center;
    font-weight: bold;
    border: 1px solid #faf4aa; }

.ie11 .wrap-product .list-product li a span {
  padding-top: 5px;
  display: inline-block; }

.ie11 .wrap-product dt {
  padding-top: 5px; }

.btn-skip {
  width: 97px;
  height: 25px;
  background-color: #7ecef4;
  color: #fff799;
  position: fixed;
  z-index: 100;
  left: 20px;
  top: 46px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.71429;
  text-align: center;
  cursor: pointer;
  transition-property: left;
  transition-duration: 0.75s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .btn-skip.hide {
    left: 120px; }

.btn-close, .btn-menu {
  position: fixed;
  left: 24px;
  top: 46px;
  z-index: 12;
  background-image: url(/assets/images/sprite-common-pc.png);
  width: 30px;
  height: 24px;
  cursor: pointer;
  transition-property: transform, opacity;
  transition-duration: 0.75s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .btn-close.hide, .btn-menu.hide {
    transform: scale(0.8);
    opacity: 0;
    z-index: 11; }

.btn-menu {
  width: 30px;
  height: 24px;
  background-position: -450px -284px; }

.btn-close {
  left: 24px;
  width: 24px;
  height: 24px;
  background-position: -476px -313px; }

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 10;
  transition-property: transform background-color;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: ease;
  background-image: url(../images/pc/bg-header.png);
  background-repeat: repeat-x;
  background-position: left bottom;
  text-align: center; }
  .header.scroll {
    background-color: rgba(255, 255, 255, 0.85); }
  .header h2 {
    margin-top: 20px;
    float: left; }
  .header nav {
    float: left;
    margin-top: 42px;
    padding-left: 30px; }
    .header nav ul {
      font-size: 0; }
    .header nav li {
      margin-left: 20px;
      display: inline-block;
      vertical-align: top;
      font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      font-size: 14px;
      margin-bottom: 16px; }
      .header nav li:last-child {
        margin-bottom: 0; }
      .header nav li a {
        display: inline-block; }
      .header nav li span {
        background-size: 251px 200.5px; }

.header-b .header nav ul li.header-news span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -196px -14px !important;
  width: 55px;
  height: 13px; }
.header-b .header nav ul li.header-introduction span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -196px -42px !important;
  width: 53px;
  height: 13px; }
.header-b .header nav ul li.header-character span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -56px -174px !important;
  width: 56px;
  height: 13px; }
.header-b .header nav ul li.header-staffcast span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: 0px -42px !important;
  width: 108px;
  height: 12px; }
.header-b .header nav ul li.header-books span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: 0px -174px !important;
  width: 56px;
  height: 14px; }
.header-b .header nav ul li.header-movie span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -196px -80px !important;
  width: 53px;
  height: 11px; }
.header-b .header nav ul li.header-onair span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -57px -145px !important;
  width: 57px;
  height: 14px; }
.header-b .header nav ul li.header-music span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -196px -156px !important;
  width: 28px;
  height: 14px; }
.header-b .header nav ul li.header-product span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: 0px -55px !important;
  width: 86px;
  height: 13px; }
.header-b .header nav ul li.header-goods span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: 0px -160px !important;
  width: 56px;
  height: 14px; }
.header-b .header nav ul li.header-special span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -87px -69px !important;
  width: 68px;
  height: 11px; }

.header-g .header nav ul li.header-news span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -196px 0px !important;
  width: 55px;
  height: 13px; }
.header-g .header nav ul li.header-introduction span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -196px -28px !important;
  width: 53px;
  height: 13px; }
.header-g .header nav ul li.header-character span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -113px -174px !important;
  width: 56px;
  height: 13px; }
.header-g .header nav ul li.header-staffcast span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -44px -29px !important;
  width: 108px;
  height: 12px; }
.header-g .header nav ul li.header-books span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -114px -160px !important;
  width: 56px;
  height: 14px; }
.header-g .header nav ul li.header-movie span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -196px -91px !important;
  width: 53px;
  height: 11px; }
.header-g .header nav ul li.header-onair span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: 0px -145px !important;
  width: 57px;
  height: 14px; }
.header-g .header nav ul li.header-music span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -196px -142px !important;
  width: 28px;
  height: 14px; }
.header-g .header nav ul li.header-product span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: 0px -69px !important;
  width: 86px;
  height: 13px; }
.header-g .header nav ul li.header-goods span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -57px -160px !important;
  width: 56px;
  height: 14px; }
.header-g .header nav ul li.header-special span {
  background-image: url(/assets/images/sprite-common-pc.png);
  background-position: -37px -100px !important;
  width: 68px;
  height: 11px; }

.wrap-youtube {
  display: none;
  z-index: 1000;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.youtube-inner {
  width: 853px;
  height: 480px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -426px;
  margin-top: -240px; }

.header-inner {
  width: 1200px;
  margin-left: auto;
  margin-right: auto; }

.footer {
  transition-property: transform;
  transition-duration: 0.75s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translate3d(0, 0, 0);
  text-align: center; }
  .footer.nav-open {
    transform: translate3d(140px, 0, 0); }
  .footer .footer-inner {
    position: relative;
    width: 100%;
    max-width: 1024px;
    min-width: 1014px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;
    padding-bottom: 50px; }
  .footer .footer-logo {
    margin-bottom: 20px; }
  .footer .copyright {
    font-size: 12px;
    font-size: 1.2rem;
    display: inline-block; }
  .footer .nav-footer {
    margin-bottom: 20px; }
    .footer .nav-footer li {
      display: inline-block;
      padding-top: 5px;
      font-size: 12px;
      font-size: 1.2rem;
      margin-right: 9px;
      margin-right: 17px;
      position: relative; }
      .footer .nav-footer li a {
        color: #cccccc;
        font-weight: bold; }
      .footer .nav-footer li:last-child {
        margin-right: 0; }
        .footer .nav-footer li:last-child:after {
          display: none; }
      .footer .nav-footer li:after {
        content: '/';
        color: #ccc;
        position: absolute;
        background-repeat: no-repeat;
        right: -12px;
        top: 5px; }
  .footer .share-footer {
    margin-bottom: 30px; }
    .footer .share-footer li {
      display: inline-block;
      margin-right: 24px;
      position: relative; }
      .footer .share-footer li:last-child {
        margin-right: 0; }
      .footer .share-footer li a {
        display: block; }
      .footer .share-footer li i {
        display: block;
        overflow: hidden;
        text-indent: -9999px;
        background-image: url(/assets/images/sprite-common-pc.png); }
      .footer .share-footer li:after {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-image: url(/assets/images/sprite-common-pc.png);
        width: 11px;
        height: 15px;
        background-position: -380px -114px; }
    .footer .share-footer .share-t a {
      padding-top: 3px; }
    .footer .share-footer .share-t i {
      width: 21px;
      height: 17px;
      background-position: -357px -114px; }
    .footer .share-footer .share-t:after {
      right: -16px;
      top: 4px; }
    .footer .share-footer .share-g i {
      width: 22px;
      height: 22px;
      background-position: -358px -58px; }
    .footer .share-footer .share-g:after {
      right: -18px;
      top: 4px; }
    .footer .share-footer .share-f i {
      width: 11px;
      height: 21px;
      background-position: -482px -284px; }
    .footer .share-footer .share-f:after {
      display: none; }

.top-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 760px;
  max-height: 1000px; }
  .top-main .txt-catch {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 137px;
    height: 337px;
    margin-left: -68px;
    margin-top: -355px;
    transform: translate3d(0, 100px, 0);
    opacity: 1; }
    .top-main .txt-catch.hide {
      transition-property: transform, opacity;
      transition-duration: 1.5s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      transform: translate3d(0, 100px, 0);
      opacity: 0; }
    .top-main .txt-catch span {
      display: block;
      overflow: hidden;
      text-indent: -9999px;
      background-image: url(../images/pc/txt-catch.png);
      width: 45px;
      height: 30px;
      position: absolute;
      opacity: 0;
      transform: translate3d(0, 30px, 0); }
      .top-main .txt-catch span.catch-1 {
        background-position: -90px 0px;
        left: 90px;
        top: 0px;
        height: 35px; }
      .top-main .txt-catch span.catch-2 {
        background-position: -90px -35px;
        left: 90px;
        top: 35px; }
      .top-main .txt-catch span.catch-3 {
        background-position: -90px -65px;
        left: 90px;
        top: 65px; }
      .top-main .txt-catch span.catch-4 {
        background-position: -90px -95px;
        left: 90px;
        top: 95px; }
      .top-main .txt-catch span.catch-5 {
        background-position: -90px -125px;
        left: 90px;
        top: 125px; }
      .top-main .txt-catch span.catch-6 {
        background-position: -45px 0px;
        left: 45px;
        top: 0px;
        height: 35px; }
      .top-main .txt-catch span.catch-7 {
        background-position: -45px -35px;
        left: 45px;
        top: 35px; }
      .top-main .txt-catch span.catch-8 {
        background-position: -45px -65px;
        left: 45px;
        top: 65px; }
      .top-main .txt-catch span.catch-9 {
        background-position: -45px -95px;
        left: 45px;
        top: 95px; }
      .top-main .txt-catch span.catch-10 {
        background-position: -45px -125px;
        left: 45px;
        top: 125px; }
      .top-main .txt-catch span.catch-11 {
        background-position: -45px -155px;
        left: 45px;
        top: 155px; }
      .top-main .txt-catch span.catch-12 {
        background-position: -45px -185px;
        left: 45px;
        top: 185px; }
      .top-main .txt-catch span.catch-13 {
        background-position: -45px -215px;
        left: 45px;
        top: 215px; }
      .top-main .txt-catch span.catch-14 {
        background-position: -45px -245px;
        left: 45px;
        top: 245px; }
      .top-main .txt-catch span.catch-15 {
        background-position: -45px -275px;
        left: 45px;
        top: 275px; }
      .top-main .txt-catch span.catch-16 {
        background-position: -45px -305px;
        left: 45px;
        top: 305px;
        height: 35px; }
      .top-main .txt-catch span.catch-17 {
        background-position: 0px 0px;
        left: 0px;
        top: 0px;
        height: 35px; }
      .top-main .txt-catch span.catch-18 {
        background-position: 0px -35px;
        left: 0px;
        top: 35px; }
      .top-main .txt-catch span.catch-19 {
        background-position: 0px -65px;
        left: 0px;
        top: 65px; }
      .top-main .txt-catch span.catch-20 {
        background-position: 0px -95px;
        left: 0px;
        top: 95px; }
      .top-main .txt-catch span.catch-21 {
        background-position: 0px -125px;
        left: 0px;
        top: 125px; }
      .top-main .txt-catch span.catch-22 {
        background-position: 0px -155px;
        left: 0px;
        top: 155px; }
      .top-main .txt-catch span.catch-23 {
        background-position: 0px -185px;
        left: 0px;
        top: 185px; }
      .top-main .txt-catch span.catch-24 {
        background-position: 0px -215px;
        left: 0px;
        top: 215px; }
    .top-main .txt-catch.show span {
      transform: translate3d(0, 0, 0);
      opacity: 1; }
      .top-main .txt-catch.show span.catch-1 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 0s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-2 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 0.1s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-3 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 0.2s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-4 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 0.3s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-5 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 0.4s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-6 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 1.4s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-7 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 1.5s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-8 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 1.6s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-9 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 1.7s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-10 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 1.8s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-11 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 1.9s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-12 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 2s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-13 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 2.1s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-14 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 2.2s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-15 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 2.3s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-16 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 2.4s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-17 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 3.3s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-18 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 3.4s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-19 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 3.5s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-20 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 3.6s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-21 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 3.7s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-22 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 3.8s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-23 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 3.9s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
      .top-main .txt-catch.show span.catch-24 {
        transition-property: transform, opacity;
        transition-duration: 1.5s;
        transition-delay: 4s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .top-main .top-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/pc/pic-main.jpg);
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    opacity: 0;
    transition-property: opacity, margin-top;
    transition-duration: 1.5s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-top: -20px; }
    .top-main .top-bg.show {
      opacity: 1;
      margin-top: 0px; }
  .top-main .top-bg2 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0; }
    .top-main .top-bg2.flash {
      -o-animation: flash 2.5s linear 1;
      -moz-animation: flash 2.5s linear 1;
      -webkit-animation: flash 2.5s linear 1;
      animation: flash 2.5s linear 1; }
  .top-main .top-bg3 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/pc/pic-main2_2.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0; }
    .top-main .top-bg3.show {
      transition-property: opacity;
      transition-duration: 1.5s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 1; }
  .top-main .top-bg3_2 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/pc/pic-main2.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0; }
    .top-main .top-bg3_2.show {
      transition-property: opacity;
      transition-duration: 1.5s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0; }
    .top-main .top-bg3_2.show2 {
      transition-property: opacity;
      transition-duration: 0.4s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0.5; }
    .top-main .top-bg3_2.flash {
      -o-animation: flash2 1.2s ease 1;
      -moz-animation: flash2 1.2s ease 1;
      -webkit-animation: flash2 1.2s ease 1;
      animation: flash2 1.2s ease 1; }
  .top-main .box-top-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none; }
  .top-main .box-top-text-inner {
    width: 100%;
    max-width: 1880px;
    min-width: 1040px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: 100%; }
  .top-main .top-logo {
    position: absolute;
    right: 280px;
    top: 18%;
    opacity: 0; }
    .top-main .top-logo.show {
      transition-property: opacity;
      transition-duration: 1.5s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 1; }
  .top-main .top-catch2 {
    display: none;
    position: absolute;
    left: 15px;
    bottom: 420px;
    opacity: 0;
    transform: translate3d(-50px, 0, 0); }
    .top-main .top-catch2.show {
      transition-property: transform, opacity;
      transition-duration: 1.5s;
      transition-delay: 1s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 1;
      transform: translate3d(0, 0, 0); }
  .top-main .box-top-bnr {
    position: absolute;
    bottom: 115px;
    right: 10px;
    opacity: 0; }
    .top-main .box-top-bnr.show {
      transition-property: opacity;
      transition-duration: 1.5s;
      transition-delay: 1.5s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 1; }
    .top-main .box-top-bnr li {
      margin-left: 18px;
      float: right; }

.nav-side-left {
  display: none;
  position: absolute;
  bottom: 20px;
  left: 20px;
  opacity: 0;
  transform: translate3d(-50px, 0, 0); }
  .nav-side-left.show {
    transition-property: transform, opacity;
    transition-duration: 1.5s;
    transition-delay: 1s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .nav-side-left ul {
    margin-bottom: 13px; }
    .nav-side-left ul li {
      margin-bottom: 11px; }
      .nav-side-left ul li a {
        display: inline-block;
        padding: 3px 24px 3px 4px;
        transition-property: background-color;
        transition-duration: 0.3s;
        transition-delay: 0s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
        .nav-side-left ul li a:hover {
          background-color: #fdf6a5; }
      .nav-side-left ul li span {
        background-size: 251px 200.5px; }
      .nav-side-left ul li.nav-side-news span {
        background-image: url(/assets/images/sprite-common-pc.png);
        background-position: 0px -129px !important;
        width: 62px;
        height: 15px; }
      .nav-side-left ul li.nav-side-introduction span {
        background-image: url(/assets/images/sprite-common-pc.png);
        background-position: -63px -129px !important;
        width: 60px;
        height: 14px; }
      .nav-side-left ul li.nav-side-character span {
        background-image: url(/assets/images/sprite-common-pc.png);
        background-position: 0px -113px !important;
        width: 64px;
        height: 16px; }
      .nav-side-left ul li.nav-side-staffcast span {
        background-image: url(/assets/images/sprite-common-pc.png);
        background-position: -44px 0px !important;
        width: 122px;
        height: 13px; }
      .nav-side-left ul li.nav-side-books span {
        background-image: url(/assets/images/sprite-common-pc.png);
        background-position: -102px -83px !important;
        width: 64px;
        height: 16px; }
      .nav-side-left ul li.nav-side-movie span {
        background-image: url(/assets/images/sprite-common-pc.png);
        background-position: 0px -189px !important;
        width: 61px;
        height: 12px; }
      .nav-side-left ul li.nav-side-onair span {
        background-image: url(/assets/images/sprite-common-pc.png);
        background-position: -37px -83px !important;
        width: 65px;
        height: 16px; }
      .nav-side-left ul li.nav-side-music span {
        background-image: url(/assets/images/sprite-common-pc.png);
        background-position: -196px -126px !important;
        width: 31px;
        height: 15px; }
      .nav-side-left ul li.nav-side-product span {
        background-image: url(/assets/images/sprite-common-pc.png);
        background-position: -44px -14px !important;
        width: 98px;
        height: 15px; }
      .nav-side-left ul li.nav-side-goods span {
        background-image: url(/assets/images/sprite-common-pc.png);
        background-position: -65px -113px !important;
        width: 64px;
        height: 15px; }
      .nav-side-left ul li.nav-side-special span {
        background-image: url(/assets/images/sprite-common-pc.png);
        background-position: -87px -55px !important;
        width: 78px;
        height: 12px; }

.nav-side-bnr {
  margin-bottom: 40px; }
  .nav-side-bnr li {
    margin-bottom: 20px;
    margin-left: 20px; }

.nav-side-right {
  display: none;
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 240px;
  opacity: 0;
  transform: translate3d(50px, 0, 0); }
  .nav-side-right.show {
    transition-property: transform, opacity;
    transition-duration: 1.5s;
    transition-delay: 1s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1;
    transform: translate3d(0, 0, 0); }

.bg-side-right {
  width: 240px;
  background-image: url(../images/pc/bg-nav-side-right.png);
  background-repeat: no-repeat;
  background-position: left 6px; }

.box-side-news {
  margin-left: 40px;
  margin-bottom: 40px; }
  .box-side-news li {
    margin-bottom: 5px; }
    .box-side-news li span {
      font-size: 10px;
      font-size: 1rem;
      line-height: 1.4;
      display: block;
      margin-bottom: 3px; }
    .box-side-news li a {
      font-size: 12px;
      font-size: 1.2rem;
      line-height: 1.16667;
      background-color: #fdf6a5;
      display: inline-block; }

.box-widget {
  width: 200px;
  height: 160px;
  overflow: scroll;
  margin-left: 40px; }

.h-side-right {
  margin-left: 40px;
  margin-bottom: 20px; }

