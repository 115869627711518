@charset "utf-8";
///////////////////////////////////////////
// motions.scss
///////////////////////////////////////////


.fadeInOut-type1 {
	opacity: 0;
	transform: translate3d(0,0,0);
	$selector: transform,opacity;
	@include trans( $selector, 1s, 0s, $easeInOutCubic);
	&.show{
		opacity: 1;
		transform: translate3d(0,0,0);
	}
}

.fadeInOut-type2 {
	opacity: 0;
	transform: translate3d(0,20px,0);
	$selector: transform,opacity;
	@include trans($selector, 1s, 0s, $easeInOutCubic);
	&.show{
		opacity: 1;
		margin-top: 1;
		transform: translate3d(0,0,0);
	}
}

.fadeoutNext {
	transform: translate3d(0,100px,0) !important;
	opacity: 0 !important;
}

.flash{
	@include animation(flash .2s linear 2);
}



