@charset "utf-8";
///////////////////////////////////////////
// utilities.scss
///////////////////////////////////////////

.u-tar { text-align:right; }
.u-tac { text-align:center; }
.u-fll{ float:left; }
.u-flr{ float:right; }

.clearfix:after {
	height: 0;
	visibility: hidden;
	content: ".";
	display: block;
	clear: both;
	font-size:0;
	line-height:0;
	overflow: hidden;
}

.clearfix {
	_height: 1px;
	min-height: 1px;/*・･*//*/
  height: auto;
  overflow: hidden;
  /**/
}

.u-show_pc {
	display:block;
	@include media(tb){
		display:none;
	}
	@include media(sp){
		display:none;
	}
}

.u-show_pctb {
	display:block;
	@include media(tb){
		display:block;
	}
	@include media(sp){
		display:none;
	}
}

.u-show_tbsp {
	display:none;
	@include media(tb){
		display:block;
	}
	@include media(sp){
		display:block;
	}
}

.u-show_tb {
	display:none;
	@include media(tb){
		display:block;
	}
	@include media(sp){
		display:none;
	}
}

.u-show_sp {
	display:none;
	@include media(tb){
		display:none;
	}
	@include media(sp){
		display:block;
	}
}

@include media(pc){
	// .u-op{
	// 	.u-op-target{
	// 		@include trans(opacity,0.75s,0,ease);
	// 	}
	// }
	// .u-op:hover{
	// 	.u-op-target{
	// 		opacity: 0.5;
	// 	}
	// }
	.u-op2{
		@include trans(opacity,.5s,0s,$easeOutCubic);
	}
	.u-op2:hover{
		opacity: 0.5;
	}
	.u-op a{
		display: block;
		background-color: #fff;
		img{
			@include trans(opacity,.5s,0s,$easeOutCubic);
		}
		&:hover	img {
			opacity: 0.6;
		}
	}
}


// スペーサー
@each $key, $value in $spacers {
  .u-spacer-bottom-#{$key} {
    margin-bottom: #{$value}px;
  }
  .u-spacer-top-#{$key} {
    margin-top: #{$value}px;
  }
}

// 画像置換






