@charset "utf-8";

// Top

.top-main  {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	min-height: 760px;
	max-height: 1000px;
	// min-width: 1024px;
	.txt-catch {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 137px;
		height: 337px;
		margin-left: -68px;
		margin-top: -355px;
		transform: translate3d(0,100px,0);
		opacity: 1;
		&.hide{
			$selector: transform,opacity;
			@include trans($selector, 1.5s, 0s, $easeInOutCubic);
			transform: translate3d(0,100px,0);
			opacity: 0;
		}
		span{
			display: block;
			overflow: hidden;
			text-indent: -9999px;
			background-image: url(../images/pc/txt-catch.png);
			width: 45px;
			height: 30px;
			position: absolute;
			opacity: 0;
			transform: translate3d(0,30px,0);
			&.catch-1 { background-position: -90px -0px; left: 90px; top: 0px; height: 35px; }
			&.catch-2 { background-position: -90px -35px; left: 90px; top: 35px; }
			&.catch-3 { background-position: -90px -65px; left: 90px; top: 65px; }
			&.catch-4 { background-position: -90px -95px; left: 90px; top: 95px; }
			&.catch-5 { background-position: -90px -125px; left: 90px; top: 125px; }
			&.catch-6 { background-position: -45px -0px; left: 45px; top: 0px; height: 35px; }
			&.catch-7 { background-position: -45px -35px; left: 45px; top: 35px; }
			&.catch-8 { background-position: -45px -65px; left: 45px; top: 65px; }
			&.catch-9 { background-position: -45px -95px; left: 45px; top: 95px; }
			&.catch-10 { background-position: -45px -125px; left: 45px; top: 125px; }
			&.catch-11 { background-position: -45px -155px; left: 45px; top: 155px; }
			&.catch-12 { background-position: -45px -185px; left: 45px; top: 185px; }
			&.catch-13 { background-position: -45px -215px; left: 45px; top: 215px; }
			&.catch-14 { background-position: -45px -245px; left: 45px; top: 245px; }
			&.catch-15 { background-position: -45px -275px; left: 45px; top: 275px; }
			&.catch-16 { background-position: -45px -305px; left: 45px; top: 305px; height: 35px; }
			&.catch-17 { background-position: -0px -0px; left: 0px; top: 0px; height: 35px; }
			&.catch-18 { background-position: -0px -35px; left: 0px; top: 35px; }
			&.catch-19 { background-position: -0px -65px; left: 0px; top: 65px; }
			&.catch-20 { background-position: -0px -95px; left: 0px; top: 95px; }
			&.catch-21 { background-position: -0px -125px; left: 0px; top: 125px; }
			&.catch-22 { background-position: -0px -155px; left: 0px; top: 155px; }
			&.catch-23 { background-position: -0px -185px; left: 0px; top: 185px; }
			&.catch-24 { background-position: -0px -215px; left: 0px; top: 215px; }
		}
		&.show{
			span{
				$selector: transform,opacity;
				transform: translate3d(0,0,0);
				opacity: 1;

				&.catch-1 { @include trans($selector, 1.5s, 0s, $easeInOutCubic); }
				&.catch-2 { @include trans($selector, 1.5s, 0.1s, $easeInOutCubic); }
				&.catch-3 { @include trans($selector, 1.5s, 0.2s, $easeInOutCubic); }
				&.catch-4 { @include trans($selector, 1.5s, 0.3s, $easeInOutCubic); }
				&.catch-5 { @include trans($selector, 1.5s, 0.4s, $easeInOutCubic); }

				&.catch-6 { @include trans($selector, 1.5s, 1.4s, $easeInOutCubic); }
				&.catch-7 { @include trans($selector, 1.5s, 1.5s, $easeInOutCubic); }
				&.catch-8 { @include trans($selector, 1.5s, 1.6s, $easeInOutCubic); }
				&.catch-9 { @include trans($selector, 1.5s, 1.7s, $easeInOutCubic); }
				&.catch-10 { @include trans($selector, 1.5s, 1.8s, $easeInOutCubic); }
				&.catch-11 { @include trans($selector, 1.5s, 1.9s, $easeInOutCubic); }
				&.catch-12 { @include trans($selector, 1.5s, 2.0s, $easeInOutCubic); }
				&.catch-13 { @include trans($selector, 1.5s, 2.1s, $easeInOutCubic); }
				&.catch-14 { @include trans($selector, 1.5s, 2.2s, $easeInOutCubic); }
				&.catch-15 { @include trans($selector, 1.5s, 2.3s, $easeInOutCubic); }
				&.catch-16 { @include trans($selector, 1.5s, 2.4s, $easeInOutCubic); }

				&.catch-17 { @include trans($selector, 1.5s, 3.3s, $easeInOutCubic); }
				&.catch-18 { @include trans($selector, 1.5s, 3.4s, $easeInOutCubic); }
				&.catch-19 { @include trans($selector, 1.5s, 3.5s, $easeInOutCubic); }
				&.catch-20 { @include trans($selector, 1.5s, 3.6s, $easeInOutCubic); }
				&.catch-21 { @include trans($selector, 1.5s, 3.7s, $easeInOutCubic); }
				&.catch-22 { @include trans($selector, 1.5s, 3.8s, $easeInOutCubic); }
				&.catch-23 { @include trans($selector, 1.5s, 3.9s, $easeInOutCubic); }
				&.catch-24 { @include trans($selector, 1.5s, 4s, $easeInOutCubic); }
			}
		}
	}
	.top-bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-image: url(../images/pc/pic-main.jpg);
		// background-size: 100%;
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
		opacity: 0;
		$selector: opacity,margin-top ;
		@include trans($selector, 1.5s, 0s, $easeInOutCubic);
		// transform: translate3d(0,20px,0);
		margin-top: -20px;
		&.show{
			opacity: 1;
			margin-top: 0px;
		}
		// &.move{
		// 	$selector: top;
		// 	@include trans($selector, 2.5s, 0s, $easeInOutCubic);
		// }
	}
	.top-bg2 {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #fff;
		opacity: 0;
		&.flash {
			@include animation(flash 2.5s linear 1);
		}
	}
	.top-bg3 {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-image: url(../images/pc/pic-main2_2.jpg);
		// background-size: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		opacity: 0;
		&.show{
			$selector: opacity;
			@include trans($selector, 1.5s, 0s, $easeInOutCubic);
			opacity: 1;
		}
	}
	.top-bg3_2 {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-image: url(../images/pc/pic-main2.jpg);
		// background-size: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		opacity: 0;
		&.show{
			$selector: opacity;
			@include trans($selector, 1.5s, 0s, $easeInOutCubic);
			opacity: 0;
		}
		&.show2{
			$selector: opacity;
			@include trans($selector, .4s, 0s, $easeInOutCubic);
			opacity: 0.5;
		}
		&.flash {
			@include animation(flash2 1.2s ease 1);
		}
	}

	.box-top-text {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// text-align: center;
		display: none;
	}
	.box-top-text-inner {
		// background-color: #f00;
		width: 100%;
		max-width: 1880px;
		min-width: 1040px;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		height: 100%;
	}
	.top-logo {
		position: absolute;
		right: 280px;
		top: 18%;
		opacity: 0;
		&.show{
			$selector: opacity;
			@include trans($selector, 1.5s, 0s, $easeInOutCubic);
			opacity: 1;
		}
		// @include mediaH(narrow){
		// 	right: 100px;
		// 	img {
		// 		height: 500px;
		// 		width: auto;
		// 	}
		// }
	}
	.top-catch2 {
		display: none;
		position: absolute;
    left: 15px;
    bottom: 420px;
		img{
			// max-width: 973px;
			// width: 95%;
			// height: auto;
		}
		opacity: 0;
		transform: translate3d(-50px,0,0);

		&.show{
		$selector: transform,opacity;
		@include trans($selector, 1.5s, 1s, $easeInOutCubic);
			opacity: 1;
					transform: translate3d(0,0,0);

		}
	}

	.box-top-bnr {
		position: absolute;
		bottom: 115px;
		right: 10px;
		opacity: 0;
		&.show{
			$selector: opacity;
			@include trans($selector, 1.5s, 1.5s, $easeInOutCubic);
			opacity: 1;
		}
		li{
			margin-left: 18px;
			float: right;
		}

		// text-align: center;
		// display: none;
	}

}

.nav-side-left {
	display: none;
	position: absolute;
	bottom: 20px;
	left: 20px;
	opacity: 0;
	transform: translate3d(-50px,0,0);

	&.show{
		$selector: transform,opacity;
		@include trans($selector, 1.5s, 1s, $easeInOutCubic);
		opacity: 1;
		transform: translate3d(0,0,0);
	}

	ul{
		margin-bottom: 13px;
		li{
			margin-bottom: 11px;
			a{
				display: inline-block;
				padding: 3px 24px 3px 4px;
				// background-color: #f00;
				@include trans(background-color, 0.3s, 0s, $easeInOutCubic);

				&:hover{
					background-color: #fdf6a5;
				}
			}
			span{
				@include sprite-size-2x($sprite-common-pc);
				@extend .sprite-basic-a;
			}
			&.nav-side-news {
				span {
					@include sprite-2x($nav-side-news);
				}
			}
			&.nav-side-introduction {
				span {
					@include sprite-2x($nav-side-introduction);
				}
			}
			&.nav-side-character {
				span {
					@include sprite-2x($nav-side-character);
				}
			}
			&.nav-side-staffcast {
				span {
					@include sprite-2x($nav-side-staffcast);
				}
			}
			&.nav-side-books {
				span {
					@include sprite-2x($nav-side-books);
				}
			}
			&.nav-side-movie {
				span {
					@include sprite-2x($nav-side-movie);
				}
			}
			&.nav-side-onair {
				span {
					@include sprite-2x($nav-side-onair);
				}
			}
			&.nav-side-music {
				span {
					@include sprite-2x($nav-side-music);
				}
			}
			&.nav-side-product {
				span {
					@include sprite-2x($nav-side-product);
				}
			}
			&.nav-side-goods {
				span {
					@include sprite-2x($nav-side-goods);
				}
			}
			&.nav-side-special {
				span {
					@include sprite-2x($nav-side-special);
				}
			}
		}
	}
}
.nav-side-bnr {
	margin-bottom: 40px;	
	li{
		margin-bottom: 20px;
		margin-left: 20px;
	}
}

.nav-side-right {
	display: none;
	position: absolute;
	bottom: 20px;
	right: 20px;
	width: 240px;
	opacity: 0;
	transform: translate3d(50px,0,0);

	&.show{
		$selector: transform,opacity;
		@include trans($selector, 1.5s, 1s, $easeInOutCubic);
		opacity: 1;
		transform: translate3d(0,0,0);
	}
}
.bg-side-right {
	width: 240px;
	background-image: url(../images/pc/bg-nav-side-right.png);
	background-repeat: no-repeat;
	background-position: left 6px;
}
.box-side-news {
	margin-left: 40px;
	margin-bottom: 40px;
	li{
		margin-bottom: 5px;
		span{
			@include font(10,14,0);
			display: block;
			margin-bottom: 3px;
		}
			
		a{
			@include font(12,14,0);
			background-color: #fdf6a5;
			display: inline-block;
		}
	}
}
.box-widget {
	width: 200px;
	height: 160px;
	overflow: scroll;
	margin-left: 40px;
}
.h-side-right {
	margin-left: 40px;
	margin-bottom: 20px;
}
