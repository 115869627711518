@charset "utf-8";
///////////////////////////////////////////
// mixin.scss
///////////////////////////////////////////

// フォントサイズ
// 引数 font-size , line-height , letter-spacing
@mixin font($fts, $lin:24 ,$ls:0) {
  $rem: $fts / 10;
  $linS: $lin / $fts;
  $ls: $ls / 1000;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
	@if $lin != $fts {
	  line-height: $linS;
	}	
	@if $ls != 0 {
	  letter-spacing:  #{$ls}em;
	}
}

// sample...
// @include font(12,240,0);

// メディアクエリ

@mixin media($media-width){
	@if $media-width == sp{
		@media only screen and (max-width:$bp-sp){
			@content;
		}
	} @else if $media-width == tb{
		@media only screen and (max-width:$bp-tb){
			@content;
		}
	} @else if $media-width == pc{
		@media only screen and (min-width: $bp-pc){
			@content;
		}
	}
}

// メディアクエリ Height

@mixin mediaH($media-heightH){
 	@if $media-heightH == supernarrow{
		@media only screen and (max-height: $bp-supernarrow){
			@content;
		}
	}@else if $media-heightH == narrow{
		@media only screen and (max-height:$bp-narrow){
			@content;
		}
	} 
}

// トランジション

$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeCustom:cubic-bezier(0.245, 0.010, 0.205, 1.005);

@mixin trans($selector:all,$time:1s,$delay:0s ,$timing:ease){
	transition-property: $selector;
	transition-duration: $time;
	transition-delay: $delay;
	transition-timing-function: $timing;
	// ease
	// linear
	// ease-in
	// ease-out
	// ease-in-out
	// cubic-bezier(数値, 数値, 数値, 数値)
}
// sample...
// @include trans(opacity, 0.3s, 0s,$easeInOutCubic);


@mixin animation($animation...) {
  -o-animation: $animation;
  -moz-animation: $animation;
  -webkit-animation: $animation;
  animation: $animation;
}


// CSS Sprite Mixins
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}
@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}
@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}
@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}
@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// CSS Sprite Mixins 2x
@mixin sprite-width-2x($sprite) {
  width: round(nth($sprite, 5) / 2);
}
@mixin sprite-height-2x($sprite) {
  height: round(nth($sprite, 6) / 2);
}
@mixin sprite-position-2x($sprite) {
  $sprite-offset-x: round(nth($sprite, 3) / 2);
  $sprite-offset-y: round(nth($sprite, 4) / 2);
  background-position: $sprite-offset-x  $sprite-offset-y !important;
}
@mixin sprite-size-2x($spritesheet) {
  background-size: (nth($spritesheet, 1) / 2) (nth($spritesheet, 2) / 2);
}
@mixin sprite-2x($sprite) {
  @include sprite-image($sprite);
  @include sprite-position-2x($sprite);
  @include sprite-width-2x($sprite);
  @include sprite-height-2x($sprite);
}

.sprite-basic{
	display: block;
	overflow: hidden;
	text-indent: -9999px;
}
.sprite-basic-a{
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-indent: -9999px;
}


