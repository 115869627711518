@charset "utf-8";

// Header

.btn-skip {
	width: 97px;
	height: 25px;
	background-color: #7ecef4;
	color: #fff799;
	position: fixed;
	z-index: 100;
	left: 20px;
	top: 46px;
	@include font(14,24,0);
	text-align: center;
	cursor: pointer;
	$selector: left;
	@include trans($selector, 0.75s, 0s, $easeInOutCubic);
	&.hide{
		left: 120px;
	}
}

.btn-close,
.btn-menu {
	position: fixed;
	left: 24px;
	top: 46px;
	z-index: 12;
	@include sprite-image($nav-menu2);
	@include sprite-width($nav-menu2);
	@include sprite-height($nav-menu2);
	cursor: pointer;
	$selector: transform,opacity;
	@include trans($selector, 0.75s, 0s, $easeInOutCubic);
	&.hide{
		transform: scale(0.8);
		opacity: 0;
		z-index: 11;
	}
}
.btn-menu {
	@include sprite-width($nav-menu2);
	@include sprite-height($nav-menu2);
	@include sprite-position($nav-menu2);	
}
.btn-close {
	left: 24px;
	@include sprite-width($nav-close3);
	@include sprite-height($nav-close3);
	@include sprite-position($nav-close3);
}

.wrap-header {
}

.header{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100px;
	z-index: 10;
	$selector: transform background-color;
	@include trans($selector, 0.3s, 0s, ease);
	background-image: url(../images/pc/bg-header.png);
	background-repeat: repeat-x;
	background-position: left bottom;
	text-align: center;
	&.scroll{
		background-color: rgba(#fff,0.85);
	}
	h2 {
		margin-top: 20px;
		float: left;
	}
	nav {
		float: left;
		margin-top: 42px;
		padding-left: 30px;
		ul {
			font-size: 0;
		}
		li{
			margin-left: 20px;
			display: inline-block;
			vertical-align: top;
			font-family: $font-min;
			font-size: 14px;
			margin-bottom: 16px;
			&:last-child{
				margin-bottom: 0;
			}
			a {
				// color: #4e6b9f;
				display: inline-block;
			}
			span{
				@include sprite-size-2x($sprite-common-pc);
				@extend .sprite-basic-a;
			}



		}
	}
}

.header-b .header nav ul li{
	&.header-news {
		span {
			@include sprite-2x($header-news-b);
		}
	}
	&.header-introduction {
		span {
			@include sprite-2x($header-introduction-b);
		}
	}
	&.header-character {
		span {
			@include sprite-2x($header-character-b);
		}
	}
	&.header-staffcast {
		span {
			@include sprite-2x($header-staffcast-b);
		}
	}
	&.header-books {
		span {
			@include sprite-2x($header-books-b);
		}
	}
	&.header-movie {
		span {
			@include sprite-2x($header-movie-b);
		}
	}
	&.header-onair {
		span {
			@include sprite-2x($header-onair-b);
		}
	}
	&.header-music {
		span {
			@include sprite-2x($header-music-b);
		}
	}
	&.header-product {
		span {
			@include sprite-2x($header-product-b);
		}
	}
	&.header-goods {
		span {
			@include sprite-2x($header-goods-b);
		}
	}
	&.header-special {
		span {
			@include sprite-2x($header-special-b);
		}
	}
}

.header-g .header nav ul li{
	&.header-news {
		span {
			@include sprite-2x($header-news-g);
		}
	}
	&.header-introduction {
		span {
			@include sprite-2x($header-introduction-g);
		}
	}
	&.header-character {
		span {
			@include sprite-2x($header-character-g);
		}
	}
	&.header-staffcast {
		span {
			@include sprite-2x($header-staffcast-g);
		}
	}
	&.header-books {
		span {
			@include sprite-2x($header-books-g);
		}
	}
	&.header-movie {
		span {
			@include sprite-2x($header-movie-g);
		}
	}
	&.header-onair {
		span {
			@include sprite-2x($header-onair-g);
		}
	}
	&.header-music {
		span {
			@include sprite-2x($header-music-g);
		}
	}
	&.header-product {
		span {
			@include sprite-2x($header-product-g);
		}
	}
	&.header-goods {
		span {
			@include sprite-2x($header-goods-g);
		}
	}
	&.header-special {
		span {
			@include sprite-2x($header-special-g);
		}
	}
}

.wrap-youtube{
	display: none;
	z-index: 1000;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #fff;
	background-color: rgba(#fff,0.9);
}
.youtube-inner{
	width: 853px;
	height: 480px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -426px;
	margin-top: -240px;
}

.header-inner {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}


