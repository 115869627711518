@charset "utf-8";

// Common

body {
	// min-width: 1000px;
	// max-width: 100%;
}

#wrapper{
	// overflow: hidden;
	position: relative;
	height: 100%;
	width: 100%;
	// min-width: 1000px;
	// min-height: 400px;
	opacity: 0;
	transform: translate3d(0,-20px,0);
	&.nav-open {
		overflow-x: hidden;
	}
	&.show {
		$selector: transform opacity;
		@include trans($selector, 1s, 0s, $easeInOutCubic);
		transform: translate3d(0,0,0);
		opacity: 1;
	}
}

.page-home #wrapper{
	transform: translate3d(0,0,0);
	opacity: 1;	
}

.main {
	// max-wi1dth: 1300px;
	min-width: 1200px;
	padding-top: 1px;
	padding-bottom: 1px;
	position: relative;
	// width :100%;
	$selector: transform;
	@include trans($selector, 0.75s, 0s, $easeInOutCubic);
	transform: translate3d(0,0,0);
	&.nav-open {
		transform: translate3d(140px,0,0);
	}
	&.news-main {
		background-color: #fff;
		background-image: url(../images/pc/bg-ground.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;
		background-attachment: fixed;
		min-height: 900px;
	}
	&.music-main
	 {
		background-color: #fff;
		background-image: url(../images/pc/bg-sky.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		background-attachment: fixed;
	}
	&.staff-main,
	&.books-main{
		background-color: #fff;
		background-image: url(../images/pc/bg-sky2.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-attachment: fixed;
	}
	&.onair-main {
		background-color: #fff;
		background-image: url(../images/pc/bg-residential.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-attachment: fixed;
	}

	&.product-main {
		background-color: #e3f6fb;
		background-image: url(../images/pc/product/bg.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;
		background-attachment: fixed;
	}

	&.cast-main {
		background-color: #fff;
		background-image: url(../images/pc/cast/bg.jpg);
		background-repeat: no-repeat;
		background-position: center top;
	}
	&.cast2-main {
		background-color: #fff;
		background-image: url(../images/pc/bg-ground.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;
		background-attachment: fixed;
	}

	&.introduction-main {
		background-color: #fff;
		background-image: url(../images/pc/bg-dragonfly.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;
		background-attachment: fixed;
	}

	&.story-main {
		background-color: #e6f3f7;
		background-image: url(../images/pc/bg-ball.png);
		background-repeat: no-repeat;
		background-position: right top;
		background-attachment: fixed;
	}

	.box-ttl {
		position: absolute;
		text-align: right;
		right: 0;
		top: 0;
		&.type2{
			top: 101px;
		}
	}
}
.box-content{
	margin-top: 163px;
	max-width: 1300px;
	min-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	&.type2 {
		margin-top: 320px;
	}
}

.box-content-inner{
	width: 650px;
	margin-left: auto;
	margin-right: auto;	
}

.box-content-inner2{
	width: 620px;
	margin-left: auto;
	margin-right: auto;	
}



@media only screen and (min-width: 1800px){
	.staff-main{
		background-size: 100%;
	}
	.introduction-main {
		background-size: 100%;		
	}
}

.common-catch {
	position: absolute;
  left: -60px;
  top: 25px;
	// padding-bottom: 20px;
	// text-align: center;
	// img{
	// 	max-width: 973px;
	// 	width: 100%;
	// 	height: auto;
	// }
}

.comment {
	// display: block;
	// position: absolute;
	// right: -19px;
	// top: -14px;
	display: inline-block;
	margin-left: 10px;
	@include sprite-image($speech);
	@include sprite-width($speech);
	@include sprite-height($speech);
	@include sprite-position($speech);
	cursor: pointer;
	@include trans(transform, 0.5s, 0s, $easeInOutCubic);
	&:hover {
		transform: translate3d(0,4px,0);
	}
	&.y {
		@include sprite-position($speech_y);
	}
	&.pos2 {
		right: 0px;
	}
	&.big {
		@include sprite-image($speech-big);
		@include sprite-width($speech-big);
		@include sprite-height($speech-big);
		@include sprite-position($speech-big);
		position: static;
		display: inline-block;
		margin-left: 10px;
		&:hover {
			transform: translate3d(0,-4px,0);
		}
	}
	&.big2 {
		@include sprite-image($speech_big);
		@include sprite-width($speech_big);
		@include sprite-height($speech_big);
		@include sprite-position($speech_big);
		position: absolute;
		left: 50px;
		bottom: 14px;
		&:hover {
			transform: translate3d(0,-4px,0);
		}
	}
	&.shine {
		@include sprite-image($speech-shine);
		@include sprite-width($speech-shine);
		@include sprite-height($speech-shine);
		@include sprite-position($speech-shine);
		&:hover {
			transform: translate3d(0,-4px,0);
		}
	}

	
}

/////// NEWS 
.wrap-news {


	padding-bottom: 1px;
	border-radius: 2px;
	.entry {
		background-color: rgba(253, 253, 249,0.8);
		border: 1px solid #d6ddd0;
		padding: 40px;
		margin-bottom: 30px;
		&.entry-special {
			border: 6px solid #6b8795;
		}

		.date {
			@include font(14,14,0);
			color: #4c5362;
			margin-bottom: 15px;
			&.date-special .cat{
				margin-left: 0;
			}
			.cat {
				display: inline-block;
				margin-left: 25px;
				@include font(11,14,0);
				border: 1px solid #88b7c9;
				font-weight: bold;
				color: #88b7c9;
				padding: 2px 3px;
				&.date-special{
					margin-left: 0;
				}
			}
		}
		.title {
			@include font(18,24,0);
			color: #104f67;
			a {
				color: #104f67;
			}
			padding-bottom: 20px;
			margin-bottom: 30px;
			border-bottom: 1px solid #104f67;
			&.title-special {
				text-align: center;
				@include font(24,30,0);
			}
		}
		.entry-share{
			text-align: right;
			font-size: 0;
			margin-bottom: 38px;

			li{
				margin-left: 30px;
				display: inline-block;
			}
			li.ico-twitter-s {
				position: relative;
				&:before{
					content: '';
					position: absolute;
					right: -18px;
					top: 0;
					@include sprite($bg-divide-s);

				}
				a{
					display: block;
					@include sprite($ico-twitter-s);
				}
			}
			li.ico-facebook-s {
				a{
					display: block;
					@include sprite($ico-facebook-s);
				}
			}
		}
		.box-entry {
			p {
				@include font(14,20,0);
				color: #4c5362;
				margin-bottom: 30px;
				&:last-child{
					margin-bottom: 0;
				}
				a {
					padding: 2px;
					line-height: 16px;
					display: inline-block;
					background-color: #fff799;
				}
			}
			img {
				max-width: 100%;
				height: auto;
				display: block;
				margin-bottom: 30px;
			}
		}
	}
	.entry-list {
		cursor: pointer;
		@include trans(background-color, 0.5s, 0s, $easeInOutCubic);
		&:hover {
			background-color: rgba(255, 247, 153,0.6);
			a {
				text-decoration: none;
			}
		}
	}
	.box-page-numbers {
		text-align: center;
	}
	ul.page-numbers {
		display: inline-block;
		background-color: #fff799;
		margin-bottom: 30px;
		li {
			display: inline-block;
			@include font(14,14,0);
			a {
				padding: 4px 3px;
				display: block;
			}
			span.current {
				padding: 4px 3px;
				color: #cccccc;
			}
		}
	}
	.box-cat {
		border: 1px solid #6a8795;
		margin-bottom: 60px;
		ul {
			text-align: center;
			padding-top: 15px;
			padding-bottom: 10px;
			li {
				display: inline-block;
				margin: 0 13px;
				font-weight: bold;
				@include font(14,14,0);
				a{
					color: #6a8795;
				}
				&.current-cat {
					a{
						color: #cccccc;
					}
				}
			}
		}
	}
	.back-index {
		text-align: center;
		margin-bottom: 60px;
		a{
			display: block;
			background-color: #fff799;
			@include font(14,14,0);
			padding: 10px;
		}
	}
}

//////// 原作情報
.wrap-books {
	width: 760px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 30px;
	.box-books-title {
		margin-bottom: 58px;
	}
	.pic-books-title {
		float: left;
		margin-right: 24px;
	}
	.txt-books-title {
		float: left;
		h2 {
			padding-top: 10px;
			@include font(30,41,0);
			color: #fff;
			font-family: $font-min;
			text-align: center;
			margin-bottom: 22px;
		}
	}
	.btn-books-title {
		text-align: center;
		a{
			display: inline-block;
			color: #7fc6ee;
			background-color: #fff100;
			@include font(18,18,0);
			padding: 10px 26px;
		}
	}

	section.list-books {
		h2 {
			margin-bottom: 38px;
			@include font(25,25,0);
			font-family: $font-min;
			color: #6a8795;
			display: inline-block;
			border: 1px solid #6a8795;
			padding: 16px 18px;
		}
		h3 {
			margin-bottom: 20px;
			@include font(20,20,0);
			color: #fff;
			text-align: center;
			span{
				display: inline-block;
				padding-bottom: 15px;
				border-bottom: 1px solid #fff;				
			}
		}
		p {
			color: #fff;
			@include font(14,14,0);
			font-weight: bold;
			text-align: center;
			margin-bottom: 20px;
		}
		.list-books1_2 {
				margin-bottom: 35px;
				text-align: center;
				span{
					display: inline-block;
				}
		}
		ul {
			margin-bottom: 20px;
			display: flex;
			// justify-content: space-between;
			flex-wrap: wrap;
			li {
				width: 114px;
				margin-right: 15px;
				margin-bottom: 20px;
				img {
					width: 100%;
					height: auto;
				}
				&:nth-child(6n){
					margin-right: 0;
				}
			}
			&.list-books1{
				li {

				}
			}
		}
	}
}

//////// キャスト

.wrap-cast {
	section {
		min-width: 550px;
		height: 453px;
		text-align: center;
		direction: rtl;
		font-size: 0;

		// vertical-align: top;
		dl {
			display: inline-block;
			vertical-align: top;
			margin: 0 -2.5px;
			font-size: 0;
			line-height: 0;
			dt {
				height: 115px;
			}
			dd {
				position: relative;
			}
		}
	}
}

//////// 登場人物

.wrap-cast2 {
	padding-top: 110px;
	position: relative;
	section {
		width: 880px;
		height: 850px;
		margin-left: auto;
		margin-right: auto;
		position: relative;

		.charactor-thumbs {
			position: absolute;
			left: 50%;
			top: -114px;
			width: 770px;
			height: 54px;
			margin-left: -394px;
		}

		div[class*="thumbs-"] {
			@include sprite-image($thumb1);
			@include sprite-width($thumb1);
			@include sprite-height($thumb1);
			// position: absolute;
			// top: 62px;
			float: right;
			margin-left: 16px;
			cursor: pointer;
			z-index: 2;
			&:hover {
				opacity: 0.7;
			}
			&.current{
				opacity: 1;
				cursor: default;		
			}
		}

		.thumbs-harada {
			@include sprite-position($thumb1);
			// right: 3px;
			&.current {
				@include sprite-position($thumb1-on);
			}
		}
		.thumbs-nagakura {
			@include sprite-position($thumb2);
			// right: 73px;
			&.current {
				@include sprite-position($thumb2-on);
			}
		}
		.thumbs-seiha {
			@include sprite-position($thumb3);
			// right: 143px;
			&.current {
				@include sprite-position($thumb3-on);
			}
		}
		.thumbs-kadowaki {
			@include sprite-position($thumb4);
			// right: 213px;
			&.current {
				@include sprite-position($thumb4-on);
			}
		}
		.thumbs-mizugaki {
			@include sprite-position($thumb5);
			// right: 283px;
			&.current {
				@include sprite-position($thumb5-on);
			}
		}
		.thumbs-kaionji {
			@include sprite-position($thumb6);
			// right: 353px;
			&.current {
				@include sprite-position($thumb6-on);
			}
		}
		.thumbs-sawaguchi {
			@include sprite-position($thumb7);
			// right: 353px;
			&.current {
				@include sprite-position($thumb7-on);
			}
		}
		.thumbs-higashidani {
			@include sprite-position($thumb8);
			// right: 353px;
			&.current {
				@include sprite-position($thumb8-on);
			}
		}
		.thumbs-nobunishi {
			@include sprite-position($thumb9);
			// right: 353px;
			&.current {
				@include sprite-position($thumb9-on);
			}
		}
		.thumbs-tomura {
			@include sprite-position($thumb10);
			// right: 353px;
			&.current {
				@include sprite-position($thumb10-on);
			}
		}
		.thumbs-yoshisada {
			@include sprite-position($thumb11);
			// right: 353px;
			&.current {
				@include sprite-position($thumb11-on);
			}
		}

		div[class*="btn-arrow-"] {
			@include sprite-image($thumb1);
			@include sprite-height($arrow-left);
			position: absolute;
			top: 9px;
			cursor: pointer;
			z-index: 3;
			&:hover {
				opacity: 0.7;
			}

		}
		.btn-arrow-left {
			@include sprite-width($arrow-left);
			@include sprite-position($arrow-left);
			left: 421px;
		}
		.btn-arrow-right {
			@include sprite-width($arrow-right);
			@include sprite-position($arrow-right);
			left: 587px;
		}
		div[class*="box-"] {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			transform: translate3d(0,-10px,0);
			z-index: 0;
			&.show {
				$selector: transform opacity;
				@include trans($selector, 1s, 0s, $easeInOutCubic);
				transform: translate3d(0,0,0);
				opacity: 1;
				z-index: 1;
			}
			// &.hide {
			// 	$selector: transform opacity;
			// 	@include trans($selector, 1s, 0s, $easeInOutCubic);
			// 	transform: translate3d(0,20px,0);
			// 	opacity: 0;
			// }

			h2{
				position: absolute;
				right: 0;
				top: 110px;
				&.type2{
					top: 30px;
				}
			}
			.face {
				position: absolute;
				right: 192px;
				top: 282px;
				@include sprite-image($thumb1);
			}
			dl {
				position: absolute;
				left: 0;
				top: 112px;
				width: 325px;

				dt {
					margin-bottom: 20px;
					ul{
						height: 185px;
						background-color: #fff799;
						position: relative;
						box-sizing: border-box;
					}
					li{
						position: absolute;
						left: 2px;
						top: 3px;
						opacity: 0;
						$selector: transform opacity;
						@include trans($selector, 1.2s, 0s, $easeInOutCubic);
						// z-index: 0;
						&.show {
							opacity: 1;
							// z-index: 1;
						}
					}

				}
				dd {
					color: #666666;
					@include font(16,26,0);
				}
			}
			ul.charactor-list {
				position: absolute;
				left: 333px;
				top: 34px;
				&.long{
					top: 10px;
					li {
						@include sprite-height($pic-chara-10_1);
					}
				}
				li {
					position: absolute;
					top: 0;
					left: 0;
					background-repeat: no-repeat;
					@include sprite-image($pic-chara-1_1);
					@include sprite-width($pic-chara-1_1);
					@include sprite-height($pic-chara-1_1);
					opacity: 0;
					$selector: transform opacity;
					@include trans($selector, 0.8s, 0s, ease);
					&.show {
						opacity: 1;
					}
				}
			}
		}

		.box-harada {
			.face {
				@include sprite-width($pic-face1);
				@include sprite-height($pic-face1);
				@include sprite-position($pic-face1);
			}
			li:nth-child(1){ @include sprite-position($pic-chara-1_1); }
			li:nth-child(2){ @include sprite-position($pic-chara-1_2); }
			li:nth-child(3){ @include sprite-position($pic-chara-1_3); }
		}
		.box-nagakura {
			.face {
				@include sprite-width($pic-face2);
				@include sprite-height($pic-face2);
				@include sprite-position($pic-face2);
			}
			li:nth-child(1){ @include sprite-position($pic-chara-2_1); }
			li:nth-child(2){ @include sprite-position($pic-chara-2_2); }
			li:nth-child(3){ @include sprite-position($pic-chara-2_3); }
		}
		.box-seiha {
			.face {
				@include sprite-width($pic-face3);
				@include sprite-height($pic-face3);
				@include sprite-position($pic-face3);
			}
			li:nth-child(1){ @include sprite-position($pic-chara-3_1); }
			li:nth-child(2){ @include sprite-position($pic-chara-3_2); }
			li:nth-child(3){ @include sprite-position($pic-chara-3_3); }
		}
		.box-kadowaki {
			.face {
				@include sprite-width($pic-face4);
				@include sprite-height($pic-face4);
				@include sprite-position($pic-face4);
			}
			li:nth-child(1){ @include sprite-position($pic-chara-4_1); }
			li:nth-child(2){ @include sprite-position($pic-chara-4_2); }
			li:nth-child(3){ @include sprite-position($pic-chara-4_3); }
		}
		.box-mizugaki {
			.face {
				@include sprite-width($pic-face5);
				@include sprite-height($pic-face5);
				@include sprite-position($pic-face5);
			}
			li:nth-child(1){ @include sprite-position($pic-chara-5_1); }
			li:nth-child(2){ @include sprite-position($pic-chara-5_2); }
			li:nth-child(3){ @include sprite-position($pic-chara-5_3); }
		}
		.box-kaionji {
			.face {
				@include sprite-width($pic-face6);
				@include sprite-height($pic-face6);
				@include sprite-position($pic-face6);
			}
			li:nth-child(1){ @include sprite-position($pic-chara-6_1); }
			li:nth-child(2){ @include sprite-position($pic-chara-6_2); }
			li:nth-child(3){ @include sprite-position($pic-chara-6_3); }
		}
		.box-sawaguchi {
			.face {
				@include sprite-width($pic-face7);
				@include sprite-height($pic-face7);
				@include sprite-position($pic-face7);
			}
			li:nth-child(1){ @include sprite-position($pic-chara-7_1); }
			li:nth-child(2){ @include sprite-position($pic-chara-7_2); }
			li:nth-child(3){ @include sprite-position($pic-chara-7_3); }
		}
		.box-higashidani {
			.face {
				@include sprite-width($pic-face8);
				@include sprite-height($pic-face8);
				@include sprite-position($pic-face8);
			}
			li:nth-child(1){ @include sprite-position($pic-chara-8_1); }
			li:nth-child(2){ @include sprite-position($pic-chara-8_2); }
			li:nth-child(3){ @include sprite-position($pic-chara-8_3); }
		}
		.box-nobunishi {
			.face {
				@include sprite-width($pic-face9);
				@include sprite-height($pic-face9);
				@include sprite-position($pic-face9);
			}
			li:nth-child(1){ @include sprite-position($pic-chara-9_1); }
			li:nth-child(2){ @include sprite-position($pic-chara-9_2); }
			li:nth-child(3){ @include sprite-position($pic-chara-9_3); }
		}
		.box-tomura {
			.face {
				@include sprite-width($pic-face10);
				@include sprite-height($pic-face10);
				@include sprite-position($pic-face10);
			}
			li{
				@include sprite-height($pic-chara-10_1);
			}
			li:nth-child(1){ @include sprite-position($pic-chara-10_1); }
			li:nth-child(2){ @include sprite-position($pic-chara-10_2); }
			li:nth-child(3){ @include sprite-position($pic-chara-10_3); }
		}
		.box-yoshisada {
			.face {
				@include sprite-width($pic-face11);
				@include sprite-height($pic-face11);
				@include sprite-position($pic-face11);
			}
			li:nth-child(1){ @include sprite-position($pic-chara-11_1); }
			li:nth-child(2){ @include sprite-position($pic-chara-11_2); }
			li:nth-child(3){ @include sprite-position($pic-chara-11_3); }
		}

		.bg-chara-shadow {
			position: absolute;
	    left: 96px;
	    // bottom: 23px;
	    // bottom: 50px;
	    top: 733px;
			@include sprite-width($bg-chara-shadow);
			@include sprite-height($bg-chara-shadow);
			@include sprite-position($bg-chara-shadow);
			@include sprite-image($bg-chara-shadow);
		}
	}
}

//////// スタッフ

.wrap-staff {
	dl{
		margin-bottom: 20px;
		dt {
			@include font(33,33,200);
			color: #fff799;
			font-family: $font-min;
			font-weight: bold;
			margin-bottom: 15px;
		}
		dd {
			@include font(14,34,200);
			color: #104f67;
			font-weight: bold;
			font-family: $font-min;
			position: relative;
		}
		&.cast-list{
			margin-bottom: 60px;
			dd {
				width: 250px;
				float: left;
			}
		}
	}
	// section {
	// 	// min-width: 600px;
	// 	height: 453px;
	// 	text-align: center;
	// 	// vertical-align: top;
	// 	dl {
	// 		display: inline-block;
	// 		vertical-align: top;
	// 		font-size: 0;
	// 		margin: 0 27px;
	// 		line-height: 0;
	// 		dt {
	// 			margin-bottom: 37px;
	// 			&.tar {
	// 				text-align: right;
	// 				img {
	// 					margin-right: 2px;
	// 				}
	// 			}
	// 		}
	// 		dd {
	// 			position: relative;
	// 		}
	// 	}
	// }

}

//////// あらすじ

.wrap-introduction {
	margin-bottom: 100px;
	text-align: center;
	h2 {
		padding-bottom: 35px;
		border-bottom: 1px solid #104f67;
		margin-bottom: 33px;
		&.h-story{
			color: #4c5261;
			@include font(34,38,0);
			font-family: $font-min;
			text-align: left;
			padding-bottom: 15px;
			margin-bottom: 20px;
			font-weight: normal;
		}
		&.h-story2{
			color: #4c5261;
			@include font(24,24,0);
			font-family: $font-min;
			text-align: left;
			margin-top: 30px;
			padding-bottom: 15px;
			margin-bottom: 20px;
			font-weight: normal;
		}
	}
	.txt-story-staff {
		color: #4c5261;
		@include font(14,20,0);
		font-family: $font-min;
		margin-bottom: 20px;
	}
	.box-story-nav {
		border: 1px solid #104f67;
		padding: 15px 0;
		margin-bottom: 70px;
		div{
			float: left;
			margin-left: 97px;
			@include font(14,25,0);
		}
		ul{
			float: right;
			margin-right: 115px;
			width: 280px;
			li{
				float: left;
				margin-left: 14px;
				@include font(14,25,0);
			}
		}
		a {
			color: #6a8795;
		}
		.current a {
			color: #ccc;
		}
	}
	.pic-story-main {
		background-size: 100%;
		background-repeat: none;
		margin-bottom: 20px;
		max-height: 360px;
		overflow: hidden;
		img {
			width: 100%;
			height: auto;
			@include trans(opacity, 0.5s, 0s, ease);
			opacity: 1;
		}
		&.hide img{
			opacity: 0;
		}
	}
	.pic-story-list {
		display: flex;
		margin-bottom: 20px;
		li{
			background-color: #000;
			cursor: pointer;
			position: relative;
			&:before{
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background-color: #000;
				opacity: 0;
				@include trans(opacity, 0.2s, 0s, ease);
			}
			img{
				
				width: 100%;
				height: auto;
			}
			&:hover,
			&.current {
				&:before{

					opacity: 0.2;
				}
			}

		}
	}
	.box-entry-story {
		text-align: left;
		@include font(14,20,0);
		color: #6a8795;
	}






}

////// コメント

.box-comment {
	display: none;
	z-index: 1000;
	background-color: #c8cacf;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	overflow: hidden;
	.box-comment-inner {
		background-color: #f2edea;
		padding: 70px;
		padding-bottom: 70px;
		.box-comment-inner2 {
			width: 720px;
			position: relative;
			margin-left: auto;
			margin-right: auto;
		}
		h2 {
			position: absolute;
			right: 0;
			top: 0;
		}
		dl{
			position: absolute;
			left: 0;
			bottom: 0;
			dt {
				margin-bottom: 20px;
			}
		}
	}
	.comment-close {
		cursor: pointer;
		height: 1000px;
		width: 100%;
		background-color: #c8cacf;
		span {
			position: relative;
			top: 30px;
			display: block;
			margin-left: auto;
			margin-right: auto;
			@include sprite-image($comment-close);
			@include sprite-width($comment-close);
			@include sprite-height($comment-close);
			@include sprite-position($comment-close);
		}
	}

	&.hatanaka {
		.box-comment-inner2 {
			height: 500px;
		}
	}
	&.uchiyama {
		.box-comment-inner2 {
			height: 500px;
		}
	}
	&.fujimaki {
		.box-comment-inner2 {
			height: 550px;
			dl {
				bottom: 115px !important;
			}
		}
	}
	&.ono {
		.box-comment-inner2 {
			height: 550px;
			dl {
				bottom: 115px !important;
			}
		}
	}
	&.kimura {
		.box-comment-inner2 {
			height: 550px;
			dl {
				bottom: 115px !important;
			}
		}
	}
	&.umehara {
		.box-comment-inner2 {
			height: 550px;
			dl {
				bottom: 115px !important;
			}
		}
	}
	&.murase {
		.box-comment-inner2 {
			height: 550px;
			dl {
				bottom: 115px !important;
			}
		}
	}
	&.ishikawa {
		.box-comment-inner2 {
			height: 550px;
			dl {
				bottom: 115px !important;
			}
		}
	}
	&.morishima {
		.box-comment-inner2 {
			height: 550px;
			dl {
				bottom: 115px !important;
			}
		}
	}
	&.goda {
		.box-comment-inner2 {
			height: 550px;
			dl {
				bottom: 115px !important;
			}
		}
	}
	&.saito {
		.box-comment-inner2 {
			height: 550px;
			dl {
				bottom: 115px !important;
			}
		}
	}


	
	&.asano {
		.box-comment-inner2 {
			height: 550px;
		}
	}
	&.mochizuki {
		.box-comment-inner2 {
			height: 550px;
			dl {
				bottom: 110px !important;
			}
		}
	}
	&.shimura {
		.box-comment-inner2 {
			height: 550px;
		}
	}
	&.kusama {
		.box-comment-inner2 {
			width: 980px;
			height: 550px;
		}
	}
	&.senju {
		.box-comment-inner2 {
			height: 516px;
			dl {
				bottom: 110px !important;
			}
		}
	}

	.bg-ball{
		position: absolute;
		left: -91px;
		bottom: 0;
		width: 118px;
		height: 86px;
		.shadow {
			position: absolute;
			left: 0;
			bottom: 0;
			@include sprite-image($comment-ball2);
			@include sprite-width($comment-ball2);
			@include sprite-height($comment-ball2);
			@include sprite-position($comment-ball2);
		}
		.ball {
			position: absolute;
			right : 0;
			top : 0;
			@include sprite-image($comment-ball);
			@include sprite-width($comment-ball);
			@include sprite-height($comment-ball);
			@include sprite-position($comment-ball);
		}
	}

}


//////// ムービー
.wrap-movie {
	width: 540px;
	margin-left: auto;
	margin-right: auto;
	ul {
		padding-bottom: 30px;
		li {
			margin-bottom: 60px;
			div{
				margin-bottom: 15px;
			}
			p {
				text-align: center;
				@include font(20,20,0);
				font-weight: bold;
			}
		}
	}
}
//////// 放送情報
.wrap-onair {
	

	h2 {
		text-align: center;
		padding-top: 112px;
		border-bottom: 1px solid #000;
		padding-bottom: 15px;
		margin-bottom: 30px;

	}
	p.info {
		// text-align: center;
		// @include font(24,47,0);
		margin-bottom: 70px;
		text-align: center;
	}
	p.note {
		@include font(12,12,0);
		margin-bottom: 60px;
		// width: 820px;
		// margin-left: auto;
		// margin-right: auto;
		text-align: left;
			color: #000;

	}

	.table-onair {
		// width: 820px;
		// margin-left: auto;
		// margin-right: auto;
		margin-bottom: 5px;
		display: flex;
		flex-wrap: wrap;
		text-align: left;
		dt {
			width: 40%;
			@include font(16,29,0);
			margin-bottom: 12px;
			font-weight: bold;
			color: #000;
		}
		dd {
			width: 60%;
			@include font(16,29,0);
			margin-bottom: 12px;
			color: #000;
			span{
				display: block;
				@include font(13,29,0);
			}
		}
	}

}


//////// 音楽
.wrap-music {
	width: 600px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 60px;
	// text-align: center;

	.box-music {
		display: flex;
		align-items: flex-end;
		margin-bottom: 20px;
	}
	.pic {
		margin-right: 25px;
	}
	h2 {
		margin-bottom: 25px;
	}
	h3 {
		border-bottom: 1px solid #83a2c5;
		margin-bottom: 20px;
		padding-bottom: 17px;
	}
	p {
		// text-align: center;
		margin-bottom: 30px;
		@include font(14,20,0);
		color: #666666;
		span{
			display: inline-block;
			transform: scaleX(0.93);
			width: 108%;
			transform-origin: left top;
		}
		a {
			// display: inline-block;
			padding: 1px;
			background-color: #fff799;
		}
	}
}

//////// Blu-ray＆DVD
.wrap-product {
	width: 798px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 94px;
	margin-bottom: 60px;

	h2 {
		margin-bottom: 45px;
	}

	p {
		// text-align: center;
		margin-bottom: 40px;
		@include font(16,32,0);
		color: #000000;
		a {
			// display: inline-block;
			padding: 3px;
			background-color: #fff799;
		}
		.note {
			@include font(12,32,0);
		}
	}
	nav {
		margin-bottom: 60px;
	}
	dl {

	}

	dt {
		color: #e3f6fb;
		@include font(16,32,0);
		background-color: #000;
		display: inline-block;
		padding: 0 10px;
		margin-bottom: 10px;
	}

	dd {
		margin-bottom: 15px;
		@include font(14,20,0);			
		color: #000000;

		ul{
			margin-bottom: 15px;
			li {
				@include font(14,20,0);
					color: #000000;
			}
		}
		ul.pic-list{
			@extend .clearfix;
			li {
				margin-right: 20px;
				margin-bottom: 20px;
				max-width: 300px;
				float: left;
			}
		}
		.text-product-common {
				@include font(20,20,0);
				strong{
					font-weight: normal;
				}
				margin-bottom: 15px;
		}
		.pic-product-common {
			margin-bottom: 15px;
		}
		// .text-product-common2 {
		// 		@include font(14,20,0);			
		// }


	}

	.list-note {
		// position: absolute;
		// right: 0;
		// bottom: 15px;
		li {
				@include font(12,20,0);
				color: #000000;
		}
	}

	.list-product{
		display: flex;
		margin-bottom: 20px;
		// width: 100%;
		li {
			box-sizing: border-box;
			width : 40px;
			cursor: pointer;
			margin-right: 20px;
			color: #83a2c5;
			text-align: center;
			a{
				display: block;
				border: 3px solid #fff799;
				@include font(14,14,0);
				padding: 9px 0;
			}
			&.long{
				width: 162px;
				a{
					padding: 9px 0px;
				}
				
				margin-right: 17px;
			}
			&.current a,
			&:hover a{
				text-decoration: none;
				background-color: #fff799;
			}
		}
	}
	.bnr-product {
		display: flex;
		li {
			background-color: #fffde4;
			width: 340px;
			margin-right: 20px;

			a{
				background-color: transparent;
				display: block;
				padding: 10px 0 10px 20px;
			}
		}
	}

	#box-products {
		// min-height: 750px;
		position: relative;
	}
	.box-product {
		// position: absolute;
		// right: 0;
		// left: 0;
		// top: 0;
		// opacity: 0;
		// transform: translate3d(20px,0,0);
		// $selector: transform opacity;
		// @include trans($selector, 0.75s, 0s, $easeInOutCubic);
		// z-index:0;
		// &.show{
		// 	transform: translate3d(0,0,0);
		// 	display: block;
		// 	opacity: 1;
		// 	z-index: 1;
		// }
	}
	.box-product-inner {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	.box-product-right {
		margin-left: 20px;
	}
	.pic-product {

	}

	.bnr-buy {
		a{
			display: block;
			width: 200px;
			height: 42px;
			background-color: #121212;
			color: #faf4aa;
			@include font(14,42,0);
			text-align: center;
			font-weight: bold;
			border: 1px solid #faf4aa;

		}
	}
}

.ie11 .wrap-product .list-product li a span{
	padding-top: 5px;
	display: inline-block;
}

.ie11 .wrap-product dt{
	padding-top: 5px;
}

