@charset "utf-8";

// box bottom

.footer-fixed {
	// position: fixed;
	// bottom: 0;
	// left: 0;
	// width: 100%;
	// background-color: $body-background;
	// min-height: 80px;
}

.footer {
	$selector: transform;
	@include trans($selector, 0.75s, 0s, $easeInOutCubic);
	transform: translate3d(0,0,0);
	text-align: center;
	&.nav-open {
		transform: translate3d(140px,0,0);
	}
	.footer-inner {
		position: relative;
		width: 100%;
		max-width: 1024px;
		min-width: 1014px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 25px;
		padding-bottom: 50px;
	}

	.footer-logo {
		margin-bottom: 20px;
	}
	.copyright {
		@include font(12,12,0);
		display: inline-block;
	}

	.nav-footer {
		margin-bottom: 20px;
		li{
			display: inline-block;
			padding-top: 5px;
			@include font(12,12,0);
			margin-right: 9px;
			a {
				color: #cccccc;
				font-weight: bold;
			}
			margin-right: 17px;
			position: relative;
			&:last-child{
				margin-right: 0;
				&:after{
					display: none;
				}
			}
			&:after {
				content: '/';
				color: #ccc;
				position: absolute;
				background-repeat: no-repeat;
				// @include sprite-image($bg-divide-s);
				// @include sprite-width($bg-divide-s);
				// @include sprite-height($bg-divide-s);
				// @include sprite-position($bg-divide-s);
		    right: -12px;
		    top: 5px;
			}

		}
	}

	.share-footer {
		margin-bottom: 30px;

		li{
			display: inline-block;

			margin-right: 24px;
			// margin-right: 12px;
			position: relative;
			&:last-child{
				margin-right: 0;
			}

			a {
				display: block;
			}
			i {
				display: block;
				overflow: hidden;
				text-indent: -9999px;
				@include sprite-image($ico-twitter-f);
			}
			&:after {
				content: '';
				position: absolute;
				background-repeat: no-repeat;
				@include sprite-image($bg-divide-s);
				@include sprite-width($bg-divide-s);
				@include sprite-height($bg-divide-s);
				@include sprite-position($bg-divide-s);
			}
		}
		.share-t {
			a{ padding-top: 3px; }
			i{
					@include sprite-width($ico-twitter-f);
					@include sprite-height($ico-twitter-f);
					@include sprite-position($ico-twitter-f);
			}
			&:after {
		    right: -16px;
		    top: 4px;
			}
		}
		.share-g {
			i{
					@include sprite-width($ico-google-f);
					@include sprite-height($ico-google-f);
					@include sprite-position($ico-google-f);
			}
			&:after {
		    right: -18px;
		    top: 4px;
			}
		}
		.share-f {
			i{
					@include sprite-width($ico-facebook-f);
					@include sprite-height($ico-facebook-f);
					@include sprite-position($ico-facebook-f);
			}
			&:after {
				display: none;
			}
		}
	}

}



