@charset "utf-8";
///////////////////////////////////////////
// color.scss
///////////////////////////////////////////

// バックグランド
$body-background: #eeeeee;

// 基本テキストカラー
$color-default : #83a2c5 ;
$color-main : #666666 ;

// リンクカラー
$color-link : #83a2c5 ;

// ホバーリンクカラー
$color-link-hover : #83a2c5 ;

// 訪問済みリンクカラー
$color-link-visited : #83a2c5 ;

// セレクションカラー
$selection-color: #e9e9e9;



